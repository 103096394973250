
import React, { } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';

// material ui
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import XIcon from '@mui/icons-material/X';

// component
//-------------------------------------------------------------------------------------------------

const isMobile = false; // window.screen.width < 600;

export default function Layout(props) {

// content
//-------------------------------------------------------------------------------------------------

  return (

    <Box component="footer" sx={{ backgroundColor: "#1976d2", p: 2, position: 'fixed', bottom: 0, left: 0, right: 0, width: 1.0, mt: 'auto', }}>

      {true &&
        <Container maxwidth="sm">

          <Stack direction="row">

            <Typography sx={{ mt: 3 }} variant="body2" align="left">
              <strong>Copyright &copy; {new Date().getFullYear()} - RCNUnited.com & David K Anderson, Sudbury MA 01776</strong>
            </Typography>

            <List sx={{ border: 1, width: 800, backgroundColor: 'white', width:600, ml:3 }} component={Stack} direction="row" spacing={0}>
              <ListItem><a href="/SiteInfo#about">About Us</a></ListItem>
              <ListItem><a href="/SiteInfo#faq">FAQ</a></ListItem>
              <ListItem><a href="/SiteInfo#contact">Contact</a></ListItem>
              <ListItem><a href="/SiteInfo#privacy">Privacy Policy</a></ListItem>
            </List>

            <Box sx={{ ml: 3, mt: 2 }}>
              <IconButton component={Link} sx={{ p: 0 }} onClick={() => window.open('https://x.com/rcnunited/', '_blank', 'noopener,noreferrer')}>
                <XIcon sx={{ }}/>
              </IconButton>
            </Box>

          </Stack>

        </Container>        
      }

      {false &&
        <Grid sx={{ ml: 30 }} container spacing={2}>

          <Grid item>
            <Typography sx={{ border: 1, mt: 1, p: 1 }} variant="body2" align="left">
              <strong>Copyright &copy; {new Date().getFullYear()} - RCNUnited.com & David K Anderson, Sudbury MA 01776</strong>
            </Typography>
          </Grid>

          <Grid item xs={isMobile ? 12 : 5}>
            <List sx={{ border: 1, ml: 1, backgroundColor: 'white' }} component={Stack} direction="row" spacing={1}>
              <ListItem><a href="/SiteInfo">About Us</a></ListItem>
              <ListItem><a href="/SiteInfo">FAQ</a></ListItem>
              <ListItem><a href="/SiteInfo">SiteInfo</a></ListItem>
              <ListItem><a href="/SiteInfo">Site Map</a></ListItem>
              <ListItem><a href="/SiteInfo">Privacy Policy</a></ListItem>
            </List>
          </Grid>

          <Grid item><Box sx={{ border: 1, ml: 3, mt: 1, p: 1, }}>V0.1.0</Box></Grid>

        </Grid>       
      }

    </Box>
  );
}
