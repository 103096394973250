
import Home from "./components/Home"; // catch 404 and send home


const AppRoutes = [
  { path: '*', element: <Home /> }
  //{
  //  //tododka: index: true, NOT SURE WHAT THIS DOES?
  //  path: '/',
  //  element: <StateHome />
  //},
];

export default AppRoutes;
