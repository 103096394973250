
//-------------------------------------------------------------------------------------------------
// RCN Application Home
//-------------------------------------------------------------------------------------------------
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import LocalStorageService from '../services/LocalStorageService';

// material ui
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Carousel from 'react-material-ui-carousel';
import Image from 'mui-image';
import SearchIcon from '@mui/icons-material/Search';

// rcn application
import { TownsClient, } from '../web-api-client.ts';

// component
//-------------------------------------------------------------------------------------------------

const isMobile = false; // window.screen.width < 600;

export default function Home(props) {

  //#region react component/state
  const [showDemoAlert, setShowDemoAlert] = useState(true);
  const [demoLocked, ] = useState(props.demoLocked);
  const navigate = useNavigate();
  const townWelcomeURL = process.env.REACT_APP_URL_TOWN_HOME; // don't expect intellisense here!!
  const [isLoggedIn,] = useState(props.applicationUser !== null);
  const [towns, setTowns] = useState(null);
  const [town, setTown] = useState(null);
  const [state,setState ] = useState(null);
  const [viewReady, setViewReady] = useState(false);
  //const [LSS,] = useState(LocalStorageService());
  //const [favoriteTowns, setFavoriteTowns] = useState([]);
  //#endregion

  useEffect(() => {

    (async () => {

      const allTowns = await (new TownsClient()).getAllTowns();

      setTowns(allTowns);

      setViewReady(true);

    })();

  }, []);

  // content
  //-------------------------------------------------------------------------------------------------

  return <Box sx={{pt:3}}>

    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Image src="../assets/rcn.gop.png" width={60} />
      <h1>Republican Committees Network United</h1>
    </Box>

    <Typography sx={{  }} variant="h6" component="h6">
      <span style={{ color: "#ac302d" }}>
        <strong>COLLABORATE,</strong> <strong>COMMUNICATE</strong> and <strong>COORDINATE</strong>
      </span>
      &nbsp;on issues of importance... to learn more visit the
      <Button onClick={() => window.open('https://massgop.com/', '_blank', 'noopener,noreferrer')}>
        <span style={{ color: "#ac302d", marginTop:3 }}><strong>MASS-GOP</strong></span>
      </Button>
      website.
    </Typography>

    {!viewReady &&
      <Box sx={{ width: '100%', mt: 1 }}>
        <LinearProgress />
      </Box>
    }

    {false && <Alert sx={{ my: 1 }} severity="error" onClose={() => { setShowDemoAlert(false) }}>{'PLEASE NOTE THAT THIS IS A DEMONSTRATION VERSION OF RCNU -- DATA SEEN MAY BE INCOMPLETE AND/OR INACCURATE'}</Alert>}
    {false && <Alert sx={{ my: 1 }} severity="error" onClose={() => { setShowDemoAlert(false) }}>{'CURRENTLY, ONLY MASSACHUSETTS THIRD MIDDLESEX AND MIDDLESEX & WORCESTER DISTRICTS DATA IS LOADED IN THE DATABASE'}</Alert>}
    {showDemoAlert && <Alert sx={{ my: 1 }} severity="error" onClose={() => { setShowDemoAlert(false) }}>{'PLEASE NOTE THAT THIS IS A DEMONSTRATION VERSION OF RCNU -- DATA MAY BE INCOMPLETE OR WRONG -- SCHEDULE A DEMONSTRATION TO LEARN MORE'}</Alert>}

    {viewReady &&
      <Grid sx={{ mt: 0 }} container spacing={3}>

        <Grid item xs={12}>
          <Paper sx={{ p: 1, backgroundColor: '#e0e0e0' }}>
            <h4>Welcome to the Republican Committees Network (RCNU) Home</h4>
            <p>If you are already a registered committee member, you can login using the menu at the top of this page. If not, feel free to look around at different towns. Select your town and ask for more infomation and maybe even <strong>join that Republican Town Committee</strong> or, <strong>help get one started</strong>.</p>
          </Paper>
        </Grid>

      </Grid>
    }

    {viewReady &&
      <>
        <Typography sx={{ mt: 4, }}>
          <h3>Select Town/City to Learn More</h3>
        </Typography>

        <Grid sx={{ mt: 0 }} container spacing={2}>

          <Grid item xs={isMobile ? 12 : 3}>
            <Autocomplete value={'Massachusetts'} disablePortal options={['Massachusetts']} sx={{}}
              popupIcon={<SearchIcon />}
              onChange={(event, newValue) => {
                setState(newValue ? newValue : null);
              }}
              renderInput={(params) => <TextField variant="standard" {...params} label="State" />}
            />
          </Grid>

          <Grid item xs={isMobile ? 12 : 9}>
            <Autocomplete value={town} disablePortal options={towns} sx={{ width: '100%', mt: 0 }}
              popupIcon={<SearchIcon />}
              onChange={(event, newValue) => {
                setTown(newValue ? newValue : null);
              }}
              onKeyDown={(event) => {
                if (event.key === 'Tab') {
                  event.key = 'Enter'; // will call `onChange`
                }
              }}
              renderInput={(params) => <TextField variant="standard" {...params} label="Town" />}
            />
          </Grid>

        <Button sx={{ ml: 1 }} style={{ textTransform: "none" }} disabled={!town || demoLocked}
            onClick={() => navigate(`${townWelcomeURL}`, { state: { townId: town.townId, townName: town.townName } })}
          >
            Click here to learn more about this committee ( those marked with * have a committee )
          </Button>

        </Grid>

        <Grid sx={{ mt: 5, border: 1, p: 1, borderRadius: 1 }} backgroundColor="#ac302d" >
        <Carousel interval={8000} >
            {
              items.map((item, i) => <Item key={i} item={item} />)
            }
          </Carousel>
        </Grid>

      </>
    }

  </Box>;

  //-------------------------------------------------------------------
  // view helpers/functions
  //-------------------------------------------------------------------
  function hasRole(role) {

    if (!props.identityUser) {
      return false;
    }

    if (props.identityUser.roles.includes(role)) return true;

    return false;
  }
}

//#region carosal stuff - https://learus.github.io/react-material-ui-carousel/
var items = [
  {
    name: "COLLABORATE",
    description: "Collaborate with committee members and other towns on special-projects, election activities, and fund raising."
  },
  {
    name: "COMMUNICATE",
    description: "Eaisily locate and communicate with local and far-away committee members from your town, district and state."
  },
  {
    name: "COORDINATE",
    description: "Plan GOP events such as parades and stand-outs, in your town or across the state."
  },
  {
    name: "SUPPORT",
    description: "Attend the state-convention as a delegate, and vote for your candidate. Distribute and place campaign signs and brochures to interested voters."
  },
  {
    name: "MEET",
    description: "Attend regularly-scheduled committee meetings to discuss business, vote on funds directed to candidates, share ideas and discoveries."
  },
  {
    name: "NETWORK",
    description: "Social networking for Republican Committee members in a private and secure environment."
  }

]
function Item(props) {
  return (
    <Paper sx={{ p: 1 }} >
      <Typography variant="h6" component="h6" style={{ color: "#ac302d" }}>
        <strong>{props.item.name}</strong>
      </Typography>
      <p>{props.item.description}</p>

      <Button href="/HomeCheckItOut" className="CheckButton">
        Check it out!
      </Button>
    </Paper>
  )
}
//#endregion