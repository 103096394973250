
//-------------------------------------------------------------------------------------------------
// RCN Application Home
//-------------------------------------------------------------------------------------------------
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import LocalStorageService from '../services/LocalStorageService';
// material ui
import { positions } from '@mui/system';
import Icon from '@mui/material/Icon';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Carousel from 'react-material-ui-carousel';
import Image from 'mui-image';
import SearchIcon from '@mui/icons-material/Search';
// rcn application
import { TownsClient, } from '../web-api-client.ts';

//-------------------------------------------------------------------------------------------------
// Component/View
//-------------------------------------------------------------------------------------------------

//#region carosal stuff - https://learus.github.io/react-material-ui-carousel/
var items = [
  {
    name: "COLLABORATE",
    description: "Collaborate with committee members and other towns on special-projects, election activities, and fund raising."
  },
  {
    name: "COMMUNICATE",
    description: "Communicate with local and far-away comittee members from your town, district and state."
  },
  {
    name: "COORDINATE",
    description: "Invite candidates to your town meeting to hear more about candidate and his/her platform."
  },
  {
    name: "SUPPORT",
    description: "Attend the state-convention as a delegate, and vote for your candidate. Distribute and place campaign signs and brochures to interested voters."
  },
  {
    name: "MEET",
    description: "Attend regularly-scheduled committee meetings to discuss business, vote on funds directed to candidates, share ideas and discoveries."
  },
  {
    name: "NETWORK",
    description: "Social networking for Republican Committee members."
  }

]
function Item(props) {
  return (
    <Paper sx={{ p:1, }}>

      <Typography variant="h6" component="h6" style={{ color: "#ac302d" }}>
        <strong>{props.item.name}</strong>
      </Typography>

      <Box sx={{ height: 100 }}>
        <p>{props.item.description}</p>
      </Box>

      <Button sx={{}} href="/HomeCheckItOut" className="CheckButton">
        Check it out!
      </Button>

    </Paper>
  )
}
//#endregion

export default function HomeMobile(props) {

  //#region react component/state
  const navigate = useNavigate();
  const isMobile = false; // window.screen.width < 600;
  const townWelcomeURL = process.env.REACT_APP_URL_TOWN_HOME; // don't expect intellisense here!!
  const [isLoggedIn,] = useState(props.applicationUser !== null);
  const [towns, setTowns] = useState(null);
  const [town, setTown] = useState(null);
  const [viewReady, setViewReady] = useState(false);
  //const [LSS,] = useState(LocalStorageService());
  //const [favoriteTowns, setFavoriteTowns] = useState([]);
  //#endregion

  useEffect(() => {

    (async () => {

      const gatResult = await (new TownsClient()).getAllTowns();

      setTowns(gatResult);

      setViewReady(true);

    })();

  }, [] );

  return <Box sx={{  }}>

    <Box sx={{ ml:0, mt:3, display: 'flex', alignItems:"center" }}>
      <Image width={100} sx={{}} src="../assets/rcn.gop.mobile.png" />
      <h2>Republican Committees Network</h2>
    </Box>

    <Typography sx={{}} variant="h6" component="h6">
      <span style={{ color: "#ac302d" }}>
        <strong>COLLABORATE,</strong><strong>COMMUNICATE</strong> and <br></br><strong>COORDINATE</strong>
      </span>
      &nbsp;on issues of importance<br></br><br></br>To learn more visit the
      <Button onClick={() => window.open('https://massgop.com/', '_blank', 'noopener,noreferrer')}>
        <span style={{ color: "#ac302d", marginTop: 3 }}><strong>MASS-GOP</strong></span>
      </Button>
      website.
    </Typography>

    <Grid sx={{ mt:3, border:1, p:1, borderRadius:1, height:250 }} backgroundColor="#ac302d" >
      <Carousel sx={{ }} >
        {
          items.map((item, i) => <Item key={i} item={item} />)
        }
      </Carousel>
    </Grid>

    {viewReady &&
      <>
        <Typography sx={{ mt:5, }}>
          <h3>Select Town/City to Learn More</h3>
        </Typography>

        <Grid sx={{ mt: 0 }} container spacing={0}>

          <Grid item xs={12}>
            <Autocomplete value={town} disablePortal options={towns} sx={{ width: '100%', mt: 0 }}
              popupIcon={<SearchIcon />}
              onChange={(event, newValue) => {
                setTown(newValue ? newValue : null);
              }}
              renderInput={(params) => <TextField variant="standard" {...params} label="Town" />}
            />

            <Button style={{ textTransform: "none" }} onClick={() => navigate(`${townWelcomeURL}`, { state: { townId: town.townId, townName: town.townName } })} disabled={!town}>
              Learn more about this committee ( those marked with * have a committee )
            </Button>
          </Grid>

        </Grid>



      </>
    }

  </Box>;

  //-------------------------------------------------------------------
  // view helpers/functions
  //-------------------------------------------------------------------

}