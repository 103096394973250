/* eslint-disable react-hooks/exhaustive-deps */

//-------------------------------------------------------------------------------------------------
// RCN - Town Overview
// - https://developers.google.com/maps/documentation/urls/get-started
//-------------------------------------------------------------------------------------------------

import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

// material ui
import { Alert, Button, Box, Typography, TextField, Paper } from '@mui/material';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';

// application
import TownWelcomeDialog from './TownWelcomeDialog';
import { TownsClient, } from '../web-api-client.ts';

//-------------------------------------------------------------------------------------------------
// Initial landing page for a town.. offer visitor to get more info
//-------------------------------------------------------------------------------------------------
export default function TownWelcome(props) {

  const navigate = useNavigate();
  const townsClient = new TownsClient();
  const townCommitteeURL = process.env.REACT_APP_URL_TOWN_COMMITTEE; // don't expect intellisense here!!

  //#region react component/state
  const [hasErrors, setHasErrors] = useState(false);
  const { state } = useLocation();
  const [town, setTown] = useState(null);
  const [user, ] = useState(props.applicationUser);
  const [viewReady, setViewReady] = useState(false);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const [, setFormData] = useState({nameTown:''});
  const [canEdit, setCanEdit] = useState(false);
  //
  const [meetLocation, setmeetLocation] = useState(false);
  const [meetDate, setmeetDate] = useState(false);
  const [welcomeMessage, setWelcomeMessage] = useState(false);
  //#endregion

  //#region event handlers
  const handleChange = (event) => {

    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (event.target.validity.valid) {
      if (name === 'meetLocation') setmeetLocation(false);
      if (name === 'meetDate') setmeetDate(false);
      if (name === 'messageWelcome') setWelcomeMessage(false);
    } else {
      if (name === 'meetLocation') setmeetLocation(true);
      if (name === 'meetDate') setmeetDate(true);
      if (name === 'messageWelcome') setWelcomeMessage(true);
    }
  };
  const handleSubmit = (event) => {

    event.preventDefault();

    if (!event.target.checkValidity()) {
      setHasErrors(true);
      return false;
    }

    setHasErrors(false);

    return true;
  };
  //#endregion

  useEffect(() => {

    //const townIdS = state != null ? state.townId : null;
    //const townIdU = user != null ? user.townId : null;
    //const townIdQ = searchParams.get('townId');

    // user can navigate here from somewhere, or click top-menu item
    const townId = state != null
      ? state.townId
      : user != null ? user.townId : null;

    const fetchData = async () => {
      return await townsClient.getTown(townId, false)
        .then(town => {
          setFormData(town); setTown(town); setViewReady(true);
        });
    }

    townId ? fetchData() : setViewReady(false); // sometimes this happens!?!?!

  }, []);

  return (viewReady &&

    <Box sx={{ mt: 3 }}>

      <Typography color="red"><h3>Republican Committees Network - Town Overview (RTC)</h3></Typography>
      <h1>Town: Massachusetts {'>'} Third Middlesex {'>'} {town.nameTown}</h1>
      {town.chairPersonFk !== null && <>
        <Typography style={{}} variant="h6" component="h6">
          <strong>Town Committee Chair:</strong> {town.nameChairFirst} {town.nameChairLast}&nbsp;&nbsp;
          <strong>District Chairs:</strong> {town.nameDistrictChairF}, {town.nameDistrictChairM}
        </Typography>
      </>
      }

      {user && user.townId === town.townId &&
        <Button endIcon={<ArrowOutwardIcon />} onClick={() => navigate(`${townCommitteeURL}`, { state: { townId: town.townId, nameTown: town.nameTown } })} disabled={false} >
          Town GOP-Committee Page (members only)
        </Button>
      }

      {!viewReady &&
        <Box sx={{ width: '100%', mt: 1 }}>
          <LinearProgress />
        </Box>
      }

      {town.chairPersonFk === null &&
        <>
          <Alert sx={{ mt: 3, mb: 3 }}>This town does not have an RTC. Discuss starting one by clicking REQUEST INFORMATION below</Alert>

          <Typography style={{}} variant="h6" component="h6">
            <strong>District Chairs:</strong> {town.nameDistrictChairF}, {town.nameDistrictChairF}
          </Typography>

        <a href={`https://www.google.com/maps/?api=1&map_action=map&center=${town.textLatitude}${town.textLongitude}`} target="_blank" rel="noopener noreferrer">see map</a>
        </>
      }

      {town.chairPersonFk !== null &&
        <Paper component="form" noValidate sx={{ mt:3, p:2 }} onSubmit={(event) => handleSubmit(event)}>

          {hasErrors && <Alert severity={'error'}>Please enter required data and try again.</Alert>}

          <TextField name="addrMeeting" label="Meeting Location" variant="standard" margin="normal" fullWidth autoFocus
            value={town.addrMeeting} onChange={(event) => handleChange(event)} inputProps={{ readOnly: !canEdit }}
            //required={false} error={meetLocation} helperText={meetLocation ? "Please enter meet location" : ""}
          />
          <a href={`https://www.google.com/maps/?api=1&map_action=map&center=${town.textLatitude}${town.textLongitude}`} target="_blank" rel="noopener noreferrer">see map</a>

          <TextField name="textDateMeet" label="Meeting Date" variant="standard" margin="normal" fullWidth
            value={town.textDateMeet} onChange={handleChange} inputProps={{ readOnly: !canEdit }}
            //required={false} error={meetDate} helperText={meetDate ? "Please enter meet date" : ""}
          />

          <TextField name="textWelcome" label="Welcome Message" variant="standard" margin="normal" fullWidth multiline rows={4}
            value={town.textWelcome} onChange={handleChange} inputProps={{ readOnly: !canEdit }}
            //required={false} error={welcomeMessage} helperText={welcomeMessage ? "Please enter a welcome message" : ""}
          />

          {
            town.nameSitesRelated.map((link, x) => (
              <Button key={x} sx={{ mt: 3 }} endIcon={<ArrowOutwardIcon />} onClick={() => window.open(link.urlLink, '_blank', 'noopener,noreferrer')}>
                See Us On {link.codeType}
              </Button>
            ))
          }

        </Paper>
      }

      <Stack sx={{ mt: 3 }} direction="row" spacing={1}>

        <Button sx={{}} variant="contained" onClick={() => setOpenInfoDialog(true)} >
          {town.chairPersonFk === null
            ? <span>REQUEST INFORMATION ABOUT STARTING A RTC...</span>
            : <span>REQUEST INFORMATION (BECOME A MEMBER, ATTEND A meet)...</span>
          }
        </Button>
        {openInfoDialog && <TownWelcomeDialog open={true} town={town} handleMessageClose={() => setOpenInfoDialog(false)} />}

        <Button sx={{  }} variant="outlined"><span>SHARE WITH A FRIEND...</span></Button>
        <Button sx={{ flex:1, }} disabled variant="outlined"><span>EDIT TOWN-OVERVIEW</span></Button>

      </Stack>

    </Box>

  )
}