
//-------------------------------------------------------------------------------------------------
// RCN Application HomeDashboard
//-------------------------------------------------------------------------------------------------

//#region imports
import * as React from 'react';
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import LocalStorageService from '../services/LocalStorageService';
// material ui
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Image from 'mui-image';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { BarChart } from '@mui/x-charts/BarChart';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Breadcrumbs from '@mui/material/Breadcrumbs';
// rcn application
import { TownsClient, EventClient, MessageClient } from '../web-api-client.ts';
import EventViewerDialog from '../components/common/EventViewerDialog';
import MessageViewerDialog from '../components/common/MessageViewerDialog';
  //#endregion 

//#region constants
const Item = styled(Paper)(({ theme }) => ({

  backgroundcolor: '#fff', ...theme.typography.body2, padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary, ...theme.applyStyles('dark', { backgroundcolor: '#1A2027', }),

}));
const bull = ( /* bullet? */
  <Box component="span" sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
    �
  </Box>
);
//#endregion

//-------------------------------------------------------------------------------------------------
// component
//-------------------------------------------------------------------------------------------------
export default function HomeDashboard({ applicationUser }) {

  //#region react component/state
  const navigate = useNavigate();
  const townWelcomeURL = process.env.REACT_APP_URL_TOWN_HOME; // don't expect intellisense here!!
  const [isLoggedIn,] = useState(applicationUser !== null);
  const [towns, setTowns] = useState([]);
  const [town, setTown] = useState(null);
  const [events, setEvents] = useState([]);
  const [messages, setMessages] = useState([]);
  const [recentMessages, setRecentMessages] = useState([]);
  const [viewReady, setViewReady] = useState(false);
  const [showDemoAlert, setShowDemoAlert] = useState(true);
  const [showEventViewerDialog, setShowEventViewerDialog] = useState(false);
  const [showMessageViewerDialog, setShowMessageViewerDialog] = useState(false);
  //const [LSS,] = useState(LocalStorageService());
  //#endregion

  useEffect(() => {

    (async () => {

      const gat = await (new TownsClient()).getAllTowns();
      setTowns(gat);

      const gae = await (new EventClient()).getEvents();
      setEvents(gae);

      const gam = await (new MessageClient()).getMessages();
      setMessages(gam);

      //todo const grm = await (new MessageClient()).getRecentMessages();
      setRecentMessages([]);

      setViewReady(true);

    })();

  }, [] );

  //-------------------------------------------------------------------------------------------------
  // content
  //-------------------------------------------------------------------------------------------------
  return <Box sx={{ mt: 3 }}>

    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Image src="../assets/rcn.gop.png" width={60} />
      <h1>Republican Committees Network (RCN)</h1>
    </Box>

    {showDemoAlert && <Alert sx={{ my: 1 }} severity="error" onClose={() => { setShowDemoAlert(false) }}>{'PLEASE NOTE THAT THIS IS A DEMONSTRATION VERSION OF RCNU -- DATA SEEN MAY BE INCOMPLETE AND/OR INACCURATE'}</Alert>}
    {false && <Alert sx={{ my: 1 }} severity="error" onClose={() => { setShowDemoAlert(false) }}>{'CURRENTLY, ONLY MASSACHUSETTS THIRD MIDDLESEX AND MIDDLESEX & WORCESTER DISTRICTS DATA IS LOADED IN THE DATABASE'}</Alert>}

    <Box sx={{ mb:0, display: 'flex', alignItems:"center"}}>

      <Typography sx={{ mt:2, mb:2 }} variant="h5" component="h5">Dashboard:</Typography>

      <Breadcrumbs sx={{ ml: 2, fontSize:25 }} aria-label="breadcrumb" separator=">">

        <Link sx={{ ml:0 }} underline="hover" color="inherit" href="#">
          Massachusetts
        </Link>

        <Link sx={{ ml:0 }} underline="hover" color="inherit" href="#">
          Third Middlesex
        </Link>
      </Breadcrumbs>

    </Box>

    <Stack sx={{mt:0, mb:4,}} direction="row" spacing={3} justifyContent="start">
      <Item sx={{ backgroundColor: "#bdbdbd" }}><strong>Number of Districts: </strong>2/38</Item>
      <Item sx={{ backgroundColor: "#bdbdbd" }}><strong>Number of Towns: </strong>4/321</Item>
      <Item sx={{ backgroundColor: "#bdbdbd" }}><strong>Number of Members: </strong>25</Item>
      <Item sx={{ backgroundColor: "#bdbdbd", flexGrow: 1 }}><strong></strong>more to come</Item>
    </Stack>

    <Grid sx={{ minHeight: 250 }} container spacing={2} alignItems="stretch">

      {/*upcoming events*/}
      <Grid sx={{}} height={'100%'} item xs={8}>

        <Stack sx={{ mb:1, justifyContent: "flex-start", alignItems: "center", }} direction="row" spacing={2}>
          <Typography sx={{ }}>
            <strong>Upcoming Events</strong>
          </Typography>
          <Button size="small" sx={{}} component={Link} href="/EventCalendar" variant="text" endIcon={<ArrowOutwardIcon />}>
            Event Center
          </Button>
        </Stack>

        <TableContainer sx={{ height: 250 }} component={Paper}>
          <Table sx={{}} size="small">
            <TableHead style={{ backgroundColor:"#bdbdbd", color: 'white', }}>
              <TableRow>
                <TableCell>Event-Name</TableCell>
                <TableCell>Sponsor</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Start/DateTime</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {events.map((row, x) => (
                <TableRow sx={{  }} key={x}>
                  <TableCell component="a" href="#" scope="row" onClick={(e) => setShowEventViewerDialog(true)}>{row.name}</TableCell>
                  <TableCell>{row.nameTownSponsor}</TableCell>
                  <TableCell>{row.codeType}</TableCell>
                  <TableCell>{row.whenStartsString}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {showEventViewerDialog && <EventViewerDialog open={showEventViewerDialog} handleMessageClose={() => setShowEventViewerDialog(false) } ></EventViewerDialog>}

      </Grid>

      {/*recent messages*/}
      <Grid sx={{}} height={'100%'} item xs={4}>

        <Stack sx={{ mb: 1, justifyContent: "flex-start", alignItems: "center", }} direction="row" spacing={2}>
          <Typography sx={{}}>
            <strong>Recent Messages</strong>
          </Typography>
          <Button size="small" sx={{}} component={Link} href="/MessageCenter" variant="text" endIcon={<ArrowOutwardIcon />}>
            Message Center
          </Button>
        </Stack>

        <TableContainer sx={{ height: 250 }} component={Paper}>
          <Table sx={{}} size="small">
            <TableHead style={{ backgroundColor:"#bdbdbd", color: 'white', }}>
              <TableRow>
                <TableCell>Subject</TableCell>
                <TableCell>From</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {messages.map((row, x) => (
                <TableRow sx={{  }} key={x}>
                  <TableCell component="a" href="#" scope="row" onClick={(e) => setShowMessageViewerDialog(true)}>{row.messageSubject}</TableCell>
                  <TableCell>{row.messageFrom}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {showMessageViewerDialog && <MessageViewerDialog open={showMessageViewerDialog} handleMessageClose={() => setShowMessageViewerDialog(false)} ></MessageViewerDialog>}

      </Grid>

    </Grid>

    {/*recent interactions*/}
    <Box sx={{ mt: 5 }}>

      <Stack sx={{ mb: 1, justifyContent: "flex-start", alignItems: "center", }} direction="row" spacing={2}>
        <Typography sx={{}}>
          <strong>Recent Member Interaction</strong>
        </Typography>
        <Button size="small" sx={{}} component={Link} href="/PeopleView" variant="text" endIcon={<ArrowOutwardIcon />}>
          Member Center
        </Button>
      </Stack>

      <Grid sx={{  }} container spacing={2} direction="row" justify="flex-start" alignItems="flex-start">
        {recentMessages.map((elem, x) => (
          <Grid item xs={3} key={x}>
            <Card style={{ backgroundColor: "#bdbdbd" }}>

              <CardHeader title={elem.interaction.nameChairFull} subheader={
                  <>
                  <Typography>Town: {elem.town}</Typography>
                  <Typography>Role: {elem.role}</Typography>
                  <Typography>Date: {elem.whenSentString}</Typography>
                  </>
                }
              />

              <CardActions sx={{mt:-3}} >
                <Button size="small" sx={{}} component={Link} href="/MessageCenter" >Message</Button>
                <Button size="small" sx={{}} component={Link} href={`/TownCommittee?townId=${elem.interaction.townFk}`} >Town</Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>

    <Grid sx={{mt:3, mb:3}} container spacing={2}>
      <Grid item xs={12}>
        <Paper>
          <Typography sx={{ml:2, width:500}} component="caption">Member Growth by Quarter (example only)</Typography>
          <BarChart
            series={[
              { data: [35, 44, 24, 34] },
              { data: [51, 6, 49, 30] },
              { data: [15, 25, 30, 50] },
              { data: [60, 50, 15, 25] },
            ]}
            height={200}
            xAxis={[{ data: ['Q1', 'Q2', 'Q3', 'Q4'], scaleType: 'band' }]}
            margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
          />
        </Paper>
      </Grid>
    </Grid>

    {viewReady && <p></p>}

  </Box>;
}