
//-------------------------------------------------------------------------------------------------
// RCN - New Message Dialog
//-------------------------------------------------------------------------------------------------

import { useState, useEffect } from "react";
//material ui
import { Alert, Button, Box, Typography, } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { AppBar, Toolbar } from '@mui/material';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// material icons
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
// rcn application
import ApplicationStateService from '../services/ApplicationStateService';
import UserLocation from '../components/common/UserLocation';
import { MessageClient } from '../web-api-client.ts';

//-------------------------------------------------------------------------------------------------
// component
//-------------------------------------------------------------------------------------------------
export default function NewMessageDialog(props) {

  //#region react/component state
  const [bodyError, setBodyError] = useState(false);
  const [subjectError, setSubjectError] = useState(false);
  const [open, setOpen] = useState(props.open);
  const [wasSent, setWasSent] = useState(false);
  const [formData, setFormData] = useState({});
  const [viewReady, setViewReady] = useState(false);
  const [alertMessage, setAlertMessage] = useState("Member(s) will receive an email with a link to the RCN message center.");
  const [alertStatus, setAlertStatus] = useState("info");
//#endregion

  useEffect(() => {

    (async () => {

      setViewReady(true);

    })(/*iife*/);

  }, []);

  //#region event handlers

  //help: https://muhimasri.com/blogs/mui-validation/#:~:text=Explore%20the%20details%20of%20validating%20Material%20UI%20(MUI)

  const handleChange = (event) => {

    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (event.target.validity.valid) {
      if (event.target.name === 'messageSubject') setSubjectError(false);
      if (event.target.name === 'messageBody') setBodyError(false);
    } else {
      if (event.target.name === 'messageSubject') setSubjectError(true);
      if (event.target.name === 'messageBody') setBodyError(true);
    }

  };
  const handleClose = () => {
    props.handleMessageClose();
  };
  const handleSave = async (event) => {

    event.preventDefault();

    if (wasSent) return; // shouldn't happen

    if (!event.target.checkValidity()) {
      setAlertMessage("Form is not complete! Please check the required (*) fields...");
      setAlertStatus("error");
      return false;
    }

    await (new MessageClient()).postMessage(
      {
        textMessageSubject: formData.messageSubject,
        textMessageBody: formData.messageBody,
        personIdSendFrom: 5050,
        personIdsSendTo: Array.prototype.map.call(props.sendTo, s => s)
      }
    );

    setAlertMessage(`Message was sent to ${props.sendTo.length} members. Dialog can be closed.`);
    setAlertStatus("success");
    setWasSent(true);
  };
  //#endregion

  //-------------------------------------------------------------------------------------------------
  // component content
  //-------------------------------------------------------------------------------------------------
  return (
    <>
      <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}
        aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description"
      >
        <AppBar sx={{ position: 'relative' }}>

          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              MESSAGE CENTER - SEND MESSAGE
            </Typography>
            <Button sk={{enabled:false}} autoFocus color="inherit" onClick={handleSave}>SEND</Button>
          </Toolbar>

        </AppBar>

        <DialogTitle id="alert-dialog-title">Enter message data and click SEND</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Alert severity={alertStatus}>{alertMessage}</Alert>

            <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={handleSave}>

              {false && <p>Send To: {Array.prototype.map.call(props.sendTo, s => " " + s.nameOwner).toString()}</p>}
              <p>Send To: {props.sendToName}</p>

              <TextField name="messageSubject" label="Message Subject" fullWidth variant="standard"
                value={formData.messageSubject}
                required={true}
                margin="normal"
                helperText={subjectError ? "Please enter message subject" : ""}
                error={subjectError}
                onChange={handleChange}
                inputProps={{ maxLength: 100 }}
              />

              <TextField xs={{}} name="messageBody" label="Message Body" fullWidth variant="standard"
                value={formData.messageBody}
                required={true}
                margin="normal"
                helperText={bodyError ? "Please enter mesage body" : ""}
                error={bodyError}
                onChange={handleChange}
                inputProps={{ maxLength: 500 }}

              />

              <Box sx={{ mt: 1 }}><p></p></Box>

              <Button disabled={wasSent} type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                SEND MESSAGE
              </Button>

            </Box>

          </DialogContentText>
        </DialogContent>

        <DialogActions style={{ justifyContent: "space-between" }}>

          <FormControlLabel disabled={true} sx={{ml:1,}} control={<Checkbox defaultChecked />}
            value={true} label="private message content"
          />

          <Button onClick={handleClose}>CANCEL</Button>

        </DialogActions>
      </Dialog>
    </>
  );
}