
//-------------------------------------------------------------------------------------------------
// RCN - People View Component
//-------------------------------------------------------------------------------------------------

import { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

import ApplicationStateService from '../services/ApplicationStateService';
import UserLocation from '../components/common/UserLocation';
import SpecialPermissions from '../components/common/SpecialPermissions';
import { TownsClient, PeopleClient } from '../web-api-client.ts';

//material ui
import { Alert, Button, Box, Link } from '@mui/material';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { DataGrid, GridActionsCellItem, gridPageCountSelector, GridPagination, useGridApiContext, useGridSelector, } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import AddLocationIcon from '@mui/icons-material/AddLocation';
import EmailIcon from '@mui/icons-material/Email';
import MuiPagination from '@mui/material/Pagination';

//#region people table
const columns = [
  { field: 'personId', headerName: 'ID', width: 70 },
  { field: 'nameTown', headerName: 'City/Town', width: 200 },
  { field: 'namePersonLast', headerName: 'LastName', width: 200 },
  { field: 'namePersonFirst', headerName: 'FirstName', width: 200 },
  { field: 'userName', headerName: 'UserName', width: 350 },
  { field: 'stateId', headerName: 'State', width: 70 },
  {
    field: 'actions', type: 'actions',
    getActions: (params) =>
      [
        <DeleteUserActionItem label="Email" showInMenu icon={<EmailIcon />} closeMenuOnClick={false} />, //deleteUser={deleteUser(params.id)}
        <DeleteUserActionItem label="Activate" showInMenu icon={<AddLocationIcon />} closeMenuOnClick={false} /> //deleteUser={deleteUser(params.id)}
      ],
    xgetActions: (params) =>
      [
        <DeleteUserActionItem label="Email" showInMenu icon={<EmailIcon />} closeMenuOnClick={false} />, //deleteUser={deleteUser(params.id)}
        <DeleteUserActionItem label="Activate" showInMenu icon={<AddLocationIcon />} closeMenuOnClick={false} /> //deleteUser={deleteUser(params.id)}
      ]
  },
];
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return { id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}`, };
}
function DeleteUserActionItem({ deleteUser, ...props }) {

  const [open, setOpen] = useState(false);

  return (
    <>
      <GridActionsCellItem {...props} onClick={() => setOpen(true)} />
      <Dialog fullScreen={"md"}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete this user?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            onClick={() => {
              setOpen(false);
              deleteUser();
            }}
            color="warning"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
//#endregion people table

//-------------------------------------------------------------------------------------------------
// component
//-------------------------------------------------------------------------------------------------
export default function PeopleView(props) {

  //#region react/component state
  const [appStateService,] = useState(props.appStateService);
  const [user,] = useState(props.applicationUser);
  const [people, setPeople] = useState([]);
  //const { state } = useLocation();
  const [viewReady, setViewReady] = useState(false);
  const [value, setValue] = useState(0);
  const [formData, setFormData] = useState({ nameFirst: 'David', nameLast: '', numZipCode: '', addrRegistered: '', email: '', password: 'RTClogin.123' });
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRows, setSelectedRows] = useState(null);
  //#endregion

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChange = (event) => {
    setFormData(event.target.value);
  };

  //#region people navigation & filtering
  const SearchBar = ({ setSearchQuery }) => (
    <Box>
      <TextField autoFocus sx={{ width: 500 }}
        id="search-bar"
        className="text"
        onChange={(e) => {
          setSearchQuery(e.target.value);
        }}
        label="search on person or town name..."
        variant="outlined"
        //placeholder="Search..."
        size="small"
        value={searchQuery}
      />
      <IconButton type="submit" aria-label="search">
        <SearchIcon style={{ fill: "blue" }} />
      </IconButton>
    </Box>
  );
  const filterData = (query, data) => {

    if (!query) { return data; }
    else {
      return data.filter((d) => {
        return d.nameTown.toLowerCase().includes(query.toLowerCase()) ||
          d.namePersonLast.toLowerCase().includes(query.toLowerCase()) ||
          d.namePersonFirst.toLowerCase().includes(query.toLowerCase())
      })
    }
  }
  const dataFiltered = filterData(searchQuery, people);
  const handleRowSelectionModelChange = (params) => {
    setSelectedRows(params);
  };
  function Pagination({ page, onPageChange, className }) {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <MuiPagination display="flex-box" sx={{ '& > .MuiPagination-ul': { display:'inline-flex', } }} style={{   }}
        color="primary"
        className={className}
        count={pageCount}
        page={page + 1}
        onChange={(event, newPage) => { onPageChange(event, newPage - 1); }}
        labelDisplayedRows={({ from, to, count, page }) => {
          return defaultLabelDisplayedRows({ from, to, count, page }) // never called
        }}
      />
    );
  }
  function CustomPagination(props) {
    return <GridPagination style={{ display: "inline-flex" }} ActionsComponent={Pagination} {...props} />;
  }
  const defaultLabelDisplayedRows: React.FC<LabelDisplayedRowsArgs> = (props): React.ReactElement<any, any> => {
    return (
      <>
        <p>
          <b> Mostrando itens {" "}
            <span style={{ color: "#FFB03A" }}>
              {props.from > 0 && props.from < 10 ? `0${props.from}-0${props.to}` : `${props.from}-${props.to}`}
            </span>
          </b>
          <p style={{ padding: 0, margin: 0 }}>total de {props.count} itens</p>
        </p>
      </>
    )
  }
  //#endregion people navigation & filtering

  useEffect(() => {

    (async () => {

      setPeople(await (new PeopleClient()).getAllPeople());
      setViewReady(true);

    }) (/*iife*/);

  }, []);

  //-------------------------------------------------------------------------------------------------
  // content
  //-------------------------------------------------------------------------------------------------
  return (viewReady &&

    <Box sx={{ mt: 3 }}>

      <h1>RC.Network Member Center</h1>
      <SpecialPermissions page="PEOPLE" id={null} applicationUser={user} />
      <UserLocation appUser={props.applicationUser} />

      <Box sx={{ width:'100%', mt:3 }}>

        {/* tab panel */}
        <Tabs sx={{ borderBottom: 1, borderColor: 'divider' }} value={value} onChange={handleTabChange}>
          <Tab label="People" {...a11yProps(0)} />
          <Tab label="Person Details" {...a11yProps(1)} />
        </Tabs>

        {/* people grid */}
        <CustomTabPanel value={value} index={0}>

          <Box display="flex" justifyContent="flex-start" alignItems="space-between" sx={{ mb: 2 }}>
            <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
            <Button style={{ marginLeft: "auto" }} variant="outlined">MESSAGE SELECTED PERSON(S)...</Button>
          </Box>

          <div style={{ height: 400, width: '100%' }}>
            <DataGrid
              pagination={false}
              slots={{
                pagination: false,
                //labelDisplayedRows: {{() => defaultLabelDisplayedRows() }}
              }}
              slotProps={{
                //pagination: {
                //  labelRowsPerPage: "You custom texttttttttttt", style: { color: 'red', m:3 },
                //},
              }}
              labelDisplayedRows={({ from, to, count, page }) => {
                return defaultLabelDisplayedRows({ from, to, count, page }); // never called
              }}
              onRowSelectionModelChange={handleRowSelectionModelChange}
              rows={dataFiltered}
              key={(x) => x.personId}
              columns={columns}
              checkboxSelection
              getRowId={(x) => x.personId}
              initialState={{ pagination: { paginationModel: { page: 0, pageSize: 5 }, }, }}
              pageSizeOptions={[/* remove from UI */]}
            />
          </div>

        </CustomTabPanel>

        {/* person detail */}
        {selectedRows && <CustomTabPanel value={value} index={1}>
          [TODO: all person details]
        </CustomTabPanel>
        }

      </Box>

    </Box>
  )
}