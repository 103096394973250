
//-------------------------------------------------------------------------------------------------
// RCN - Town Committee Members
//-------------------------------------------------------------------------------------------------
import { useState, useEffect, useRef } from "react";
import { styled } from '@mui/material/styles';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useForm, } from 'react-hook-form';

// rcn application 
import { TownsClient, UserClient, PeopleClient } from '../web-api-client.ts';
import ApplicationStateService from '../services/ApplicationStateService';
import UserLocation from '../components/common/UserLocation';
import SpecialPermissions from '../components/common/SpecialPermissions';
import MessageMembersButton from '../components/common/MessageMembersButton';

// material ui
import { Alert, Button, Box, Typography, TextField, Paper, Grid, } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import DeleteIcon from '@mui/icons-material/Delete';
//import EditIcon from '@mui/icons-material/Edit';
//import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';

// component
//-------------------------------------------------------------------------------------------------
export default function TownCommitteeMembers({user}) {

  //#region react state
  const [viewReady, setViewReady] = useState(false);
  const {state} = useLocation();
  const [searchParams ] = useSearchParams();
  //const [user,] = useState(props.applicationUser);
  const [members, setMembers] = useState([]);
  const [value, setValue] = useState(0);
  //#endregion

  useEffect(() => {

    (async () => {

      const api = new UserClient();

      // 20 Third Middlesex
      //await api.user_CreateUser({ PersonId: 4586, LoginId: 'Deshey6482@aol.com', LoginPassword: 'rtc@2024Login', LoginEmail: 'Deshey6482@aol.com', });
      //await api.user_CreateUser({ PersonId: 4589, LoginId: 'michael_j_benn@hotmail.com', LoginPassword: 'rtc@2024Login', LoginEmail: 'michael_j_benn@hotmail.com', });
      //await api.user_CreateUser({ PersonId: 4656, LoginId: 'aseferian@gmail.com', LoginPassword: 'rtc@2024Login', LoginEmail: 'aseferian@gmail.com', });
      //await api.user_CreateUser({ PersonId: 4657, LoginId: 'StephenBinder@noreply.com', LoginPassword: 'rtc@2024Login', LoginEmail: 'StephenBinder@noreply.com', });
      //await api.user_CreateUser({ PersonId: 4752, LoginId: 'sudburyrtc@icloud.com', LoginPassword: 'rtc@2024Login', LoginEmail: 'sudburyrtc@icloud.com', });

      // 36 Middlesex & Worcester
      //await api.user_CreateUser({ PersonId: 4649, LoginId: 'jekeiselbach@gmail.com', LoginPassword: 'rtc@2024Login', LoginEmail: 'jekeiselbach@gmail.com', });
      //await api.user_CreateUser({ PersonId: 4605, LoginId: 'johnstrang944@gmail.com', LoginPassword: 'rtc@2024Login', LoginEmail: 'johnstrang944@gmail.com', });
      //await api.user_CreateUser({ PersonId: 4614, LoginId: 'scyganiewicz48@gmail.com', LoginPassword: 'rtc@2024Login', LoginEmail: 'scyganiewicz48@gmail.com', });
      //await api.user_CreateUser({ PersonId: 4587, LoginId: 'ClintonRTC@outlook.com', LoginPassword: 'rtc@2024Login', LoginEmail: 'ClintonRTC@outlook.com', });
      //await api.user_CreateUser({ PersonId: 4660, LoginId: 'mikec1959@aol.com', LoginPassword: 'rtc@2024Login', LoginEmail: 'mikec1959@aol.com', });
      //await api.user_CreateUser({ PersonId: 4756, LoginId: 'chair@townsendgop.org', LoginPassword: 'rtc@2024Login', LoginEmail: 'chair@townsendgop.org', });
      //await api.user_CreateUser({ PersonId: 4774, LoginId: 'Kmoore9798@proton.com', LoginPassword: 'rtc@2024Login', LoginEmail: 'Kmoore9798@proton.com', });
      //await api.user_CreateUser({ PersonId: 4560, LoginId: 'g_slieker@prodigy.net', LoginPassword: 'rtc@2024Login', LoginEmail: 'g_slieker@prodigy.net', });

      // 4 Bristol & Norfolk
      //await api.user_CreateUser({ PersonId: 4556, LoginId: 'steve.semple@attleborogop.com', LoginPassword: 'rtc@2024Login', LoginEmail: 'steve.semple@attleborogop.com', });
      //await api.user_CreateUser({ PersonId: 4612, LoginId: 'lilacs1@live.com', LoginPassword: 'rtc@2024Login', LoginEmail: 'lilacs1@live.com', });
      //await api.user_CreateUser({ PersonId: 4668, LoginId: 'Kozesq@gmail.com', LoginPassword: 'rtc@2024Login', LoginEmail: 'Kozesq@gmail.com', });
      //await api.user_CreateUser({ PersonId: 4743, LoginId: 'mira.belenkiy@gmail.com', LoginPassword: 'rtc@2024Login', LoginEmail: 'mira.belenkiy@gmail.com', });
      //await api.user_CreateUser({ PersonId: 4760, LoginId: 'gracephd@mac.com', LoginPassword: 'rtc@2024Login', LoginEmail: 'gracephd@mac.com', });

      const townIdS = state != null ? state.townId : null;
      const townIdU = user != null ? user.townId : null;
      const townIdQ = searchParams.get('townId');

      const townId = townIdQ != null ? townIdQ : townIdS != null ? townIdS : user.townId;

      if (townId != null) {

        //setFormData({townId:townId});

        //const apiTown = await (new TownsClient()).getTown(townId, true); // true means get memebers for town
        //setTown(apiTown);
        const apiMembers = await (new PeopleClient()).getAllPeople(townId);
        setMembers(apiMembers);

        //setIsChair(user.personId === apiTown.chairPersonFk);

        setViewReady(true);

      } else
      {
        navigate("/Home"); //todo: not supposed to be here. must have typed url
      }

    })();

  }, []); // just once

  //#region event handlers
  //#endregion

  //#region table, constants
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const navigate = useNavigate();

  const columns = [
    { field: 'namePersonLast', headerName: 'First name', width: 130, headerClassName: 'super-app-theme--header' },
    { field: 'namePersonFirst', headerName: 'Last name', width: 130, headerClassName: 'super-app-theme--header' },
    { field: 'emailPerson', headerName: 'Email', width: 250, headerClassName: 'super-app-theme--header' },
    { field: 'nameIdentity', headerName: 'LoginName', width: 250, headerClassName: 'super-app-theme--header' },
    {
      field: 'isChair', headerName: 'IsChair', width: 75, headerClassName: 'super-app-theme--header', valueGetter: (value) => {
        if (!value) {
          return "---";  //todo: why null?
        }
        return value === true ? "YES" : "NO"; // Convert the boolean value to a YES or NO
      },
    },
    { field: 'statPerson', headerName: 'Status', width: 100, headerClassName: 'super-app-theme--header' },
    {
      field: 'actions', headerName: 'Actions', type: 'actions', flex: 1, align: "right", headerAlign: 'right', headerClassName: 'super-app-theme--header', getActions: (params) =>
        [
          <DeleteUserActionItem label="Delete" showInMenu icon={<DeleteIcon />} closeMenuOnClick={false}/>
        ]
    },
  ];
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 0 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  function a11yProps(index) {
    return { id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}`, };
  }

  function DeleteUserActionItem({ deleteUser, ...props }) {

    const [open, setOpen] = useState(false);

    return (
      <>
        <GridActionsCellItem {...props} onClick={() => setOpen(true)} />
        <Dialog open={open} fullWidth={'lg'} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description"
          onClose={() => setOpen(false)}
        >
          <DialogTitle id="alert-dialog-title">Delete this member?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button color="warning" autoFocus onClick={() => {
                setOpen(false);
                //deleteUser();
              }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
  //#endregion

  // content
  //-------------------------------------------------------------------------------------------------
  return <Box sx={{ mt: 2, p: 2 }}>
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid sx={{
        '& .super-app-theme--header': { backgroundColor: '#bbdefb', },
        '.MuiTablePagination-displayedRows': {
          'margin-top': '1em',
          'margin-bottom': '1em'
        },
        '.MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel': {
          'margin-top': '1em',
          'margin-bottom': '1em'
        }
      }}
        density="compact"
        rows={members} columns={columns}
        checkboxSelection
        getRowId={(x) => x.personId}
        initialState={{ pagination: { paginationModel: { page: 0, pageSize: 10 }, }, }}
        pageSizeOptions={[5, 10, 15, 25]}
      />
    </div>
  </Box>
}