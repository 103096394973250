
//-------------------------------------------------------------------------------------------------
// RCN - State Home Page
//-------------------------------------------------------------------------------------------------
import { useState, useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";

//material ui
import { Button, Box, Link, Typography, Stack } from '@mui/material';
import { Tabs, Tab } from '@mui/material';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { DataGrid, GridActionsCellItem, gridClasses } from '@mui/x-data-grid';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

// material icons
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import EmailIcon from '@mui/icons-material/Email';

// rcn application
import UserLocation from '../components/common/UserLocation';
import MessageMembersButton from '../components/common/MessageMembersButton';
import SpecialPermissions from '../components/common/SpecialPermissions';
import StateDistrictOnboarding from '../components/StateDistrictOnboarding';

//#region districts table
const columns = [
  {
    field: 'nameDistrict', headerName: 'District', width: 325, headerClassName: 'super-app-theme--header', renderCell: (cellValues) => {
      return <Link component={RouterLink}
        to={{ pathname: "/DistrictHome", search: "?districtId=" + cellValues.row.districtId, state: { /*notused*/ } }}>
        {cellValues.row.nameDistrict}
      </Link>;
    }
  },
  { field: 'nameChairManFull', headerName: 'Chair', width: 175, headerClassName: 'super-app-theme--header' },
  { field: 'nameChairWomanFull', headerName: 'Co-Chair', width: 180, headerClassName: 'super-app-theme--header' },
  { field: 'codeStatus', headerName: 'Status', width: 100, align: "center", headerClassName: 'super-app-theme--header' },
  { field: 'numTownsCountActive', headerName: '#Towns', width: 75, align: "center", headerClassName: 'super-app-theme--header' },
  { field: 'numTownsCountInActive', headerName: '#Active', width: 75, align: "center", headerClassName: 'super-app-theme--header' },
  { field: 'numMembersCount', headerName: '#Members', width: 80, align: "center", headerClassName: 'super-app-theme--header' },
  {
    field: 'actions', headerName: 'Actions', type: 'actions', flex: 1, align: "right", headerAlign:"right", headerClassName: 'super-app-theme--header', getActions: () =>
      [
        <GridActionsCellItem disabled={true} label={"nothing to see here... yet"} showInMenu={true} />,
      ]
  },
];
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return { id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}`, };
}
//#endregion

// component
//-------------------------------------------------------------------------------------------------
export default function StateHome({ applicationUser, appStateService }) {

  //#region react/component state
  const querySearch = useLocation().search;

  //const [appStateService,] = useState(appStateService);
  const [user,] = useState(applicationUser);
  const [, setPerson] = useState(null);
  const [districts, setDistricts] = useState([]);
  const [state, setState] = useState(null);
  const [viewReady, setViewReady] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [formData, setFormData] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [hideDistricts, setHideDistricts] = useState(true);
  const [, setEnableOnboarding] = useState(false);
  const [selectedIds, setSelectedIds] = useState(null);
  const [showOnboarding, setShowOnboarding] = useState(false);
  //#endregion react/component state

  useEffect(() => {

    let params = new URLSearchParams(querySearch);
    let searchTerm = params.get('stateId');
    if (!searchTerm) searchTerm = applicationUser.stateId;

    (async () => {

      const s = await appStateService.state(searchTerm); setState(s);
      const p = await appStateService.person(); setPerson(p);
      const d = await appStateService.districts(searchTerm); setDistricts(d);

      setFormData(s);
      setViewReady(true);

    })(/*iife*/);

  }, []);

  //#region event handlers
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleChange = (event) => {

    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
  };
  const getSelectedNames = () => {

    let names = "";
    if (selectedIds) {
      selectedIds.forEach((id, x) => {
        names = names + districts.find(element => element.districtId === id).nameChairManFull
        if (x + 1 < selectedIds.length) { names = (names + ", ") }
      });
    }

    return names;
  }
  const getSelectedPeople = () => {

    let ids = [];
    if (selectedIds) {
      // for each district id, get the chair id
      selectedIds.forEach((id) => {
        ids.push(districts.find(element => element.districtId === id).personIdChairMale); 
      });
    }

    return ids;
  }
  const disableOnboarding = () => {

    if (!selectedIds) return false; // allow new town
    if (selectedIds.length > 1) return true;

    const district = districts.find((t) => t.districtId === selectedIds[0]);

    if (district && district.isActive) {
      return true; // disable it
    }
    else {
      return false; // enable it
    }
  }
  //#endregion

  //#region districts filtering
  const SearchBar = ({ setSearchQuery }) => (
    <Box>
      <TextField autoFocus sx={{ width: 500 }}
        type="search"
        id="search-bar"
        className="text"
        onChange={(e) => {
          setSearchQuery(e.target.value);
        }}
        label="search on district or chair name..."
        variant="outlined"
        //placeholder="Search..."
        size="small"
        value={searchQuery}
      />
      <IconButton type="submit" aria-label="search">
        <SearchIcon style={{ fill: "blue" }} />
      </IconButton>
    </Box>
  );
  const filterData = (query, data) => {

    if (query !== "") {
      return data.filter((d) => {
        return d.nameDistrict.toLowerCase().includes(query.toLowerCase())
      })
    }
    // or do this (when checkbox changed)
    return data.filter((d) => { return d.codeStatus === "ACTIVE" || !hideDistricts });

  }
  const dataFiltered = filterData(searchQuery, districts);
  const handleHideDistricts = (event) => {

    setHideDistricts(event.target.checked);
    filterData("", districts);

  };
  //#endregion districts filtering

  // content
  //-------------------------------------------------------------------------------------------------
  return (viewReady &&

    <Box sx={{ mt: 3 }}>

      <Typography color="red"><h3>Republican Committees Network - State View (RSC)</h3></Typography>
      <h1>State: {state.nameState}</h1>
      <SpecialPermissions page="STATE" id={state.personIdChair} applicationUser={user} />
      <UserLocation appUser={applicationUser} />
      <Typography style={{}} variant="h6" component="h6">
        <strong>State Committee Chair:</strong> {state.nameChair}
        <MessageMembersButton buttonText="MESSAGE STATE CHAIR" sendTo={[state.personIdChair]} iconOnly={true} sendToName={state.nameChair} />
      </Typography>

      <Box sx={{ width: '100%' }}>

        <Tabs sx={{ mt: 1, mb: 3, borderBottom: 1, borderColor: 'divider' }} value={tabValue} onChange={handleTabChange} >
          <Tab label="State" {...a11yProps(0)} />
          <Tab label="Districts" {...a11yProps(1)} />
        </Tabs>

        {/* State Info Tab */}
        <CustomTabPanel sx={{}} value={tabValue} index={0}>

          <Button sx={{ p: 0 }} endIcon={<ArrowOutwardIcon />} onClick={() => window.open(state.urlPartyHome, '_blank', 'noopener,noreferrer')}>
            Visit the State GOP-Committee Website
          </Button>

          <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleSubmit} >

            <TextField required variant="standard" margin="normal" fullWidth id="nameOwner" label="Product Owner" name="nameOwner" autoFocus
              value={formData.nameOwner} onChange={handleChange} />

            <TextField required variant="standard" margin="normal" fullWidth id="nameOwner" label="Office Address" name="nameOwner" autoFocus
              value={'400 W Cummings Park Ste 5650, Woburn, MA 01801'} onChange={handleChange} />

            <TextField required variant="standard" margin="normal" fullWidth id="emailOwner" label="Committee Chair" name="emailOwner"
              value={'Amy Carnevale'} onChange={handleChange}
              InputProps={{
                endAdornment: <InputAdornment position="end"><EmailIcon /></InputAdornment>
              }}
            />

            <TextField required variant="standard" margin="normal" fullWidth id="nameChair" label="National Committeeman" name="nameChair"
              value={'Brad Wyatt'} onChange={handleChange}
              InputProps={{
                endAdornment: <InputAdornment position="end"><EmailIcon /></InputAdornment>
              }}
            />

            <TextField required variant="standard" margin="normal" fullWidth id="nameChairNational" label="National Committeewoman" name="nameChairNational"
              value={'Janet Fogarty'}
              onChange={handleChange}
              InputProps={{
                endAdornment: <InputAdornment position="end"><EmailIcon /></InputAdornment>
              }}
            />

            <Box sx={{ mt: 1 }}><p></p></Box>

            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              SAVE CHANGES
            </Button>

          </Box>

        </CustomTabPanel>

        {/* District(s) Tab */}
        <CustomTabPanel value={tabValue} index={1}>

          <Box sx={{ mb: 2, display: 'flex' }}>

            <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />

            <Button sx={{ ml: 22 }} variant="outlined"
              onClick={() => setShowOnboarding(true)}
              disabled={disableOnboarding()}
            >
              {`DISTRICT ${selectedIds ? "" : "[new]"} ONBOARDING...`}
            </Button>
            {showOnboarding &&
              <StateDistrictOnboarding

                refreshDistricts={() => {

                  (async () => {

                    const districts = await await appStateService.districts('MA');
                    setDistricts(districts);

                  })(/*iife*/);

                }}

                isOpen={showOnboarding}
                setOpen={() => setShowOnboarding(false)}
                name={"Configure New District"}
                id={selectedIds ? selectedIds[0] : null}>
              </StateDistrictOnboarding>              
            }

            <MessageMembersButton disabled={selectedIds} buttonText="MESSAGE SELECTED DISTRICT CHAIR(S)" sendTo={getSelectedPeople()} iconOnly={false} sendToName={getSelectedNames()} />

          </Box>

          <Box sx={{ width: '100%',

            [`.${gridClasses.cell}.cold`]: { },
            [`.${gridClasses.cell}.hot`]: { color: 'green', },
          }}>

            <DataGrid sx={{

              '& .super-app-theme--header': { backgroundColor: '#bbdefb', },

              '.MuiTablePagination-displayedRows': {
                'margin-top': '1em',
                'margin-bottom': '1em'
              },
              '.MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel': {
                'margin-top': '1em',
                'margin-bottom': '1em'
              }
            }} density="compact"

              rows={dataFiltered} columns={columns} key={(x) => x.districtId}

              checkboxSelection
              getRowId={(x) => x.districtId}
              getCellClassName={(params) => {
                if (params.field === 'codeStatus' && params.value != null) {
                  return params.value === "ACTIVE" ? 'hot' : 'cold';
                }
                return '';
              }}

              pagination={true}
              initialState={{ pagination: { paginationModel: { pageSize: 10 }, }, }}

              pageSizeOptions={[5, 10, 25]}
              onRowSelectionModelChange={(ids) => {

                setSelectedIds(ids.length > 0 ? ids : null);

                if (ids.length === 1) {
                  const selectedRowData = districts.filter((row) => row.districtId === ids[0])[0];
                  setEnableOnboarding(!selectedRowData.isActive);
                }
                else {
                  setEnableOnboarding(false);
                }

              }}
              localeText={{ noRowsLabel: <Typography sx={{}} style={{}} ><Box sx={{ }}>Now Rows To See Here</Box></Typography> }}
              slots={{
                //loadingOverlay: {
                //  variant: 'linear-progress',
                //  noRowsVariant: 'skeleton',
                //},
                //pagination: CustomPagination,
                noRowsOverlay: () =>
                {
                  <Stack sx={{}} style={{}} height="100%" alignItems="center" justifyContent="center">
                  </Stack>
                }
,
                //noResultsOverlay: () => (
                //  <Stack sx={{ }} height="100%" alignItems="center" justifyContent="center">
                //    Local filter returns no result
                //  </Stack>
                //)
              }} />

          </Box>

          <FormControlLabel control={<Checkbox defaultChecked />}
            value={hideDistricts}
            label={<Typography>Hide districts that are not <span style={{ color: 'green' }} ><strong>ACTIVE</strong></span> in the RC.Network</Typography>}
            onClick={handleHideDistricts} />

        </CustomTabPanel>

      </Box>

    </Box>

  )
}