/* eslint-disable no-undef */

import React, { } from 'react';
import { Container } from 'reactstrap';

import NavMenu from './NavMenu';
import NavMenuMobile from './NavMenuMobile';
//import LayoutFooter from '../components/LayoutFooter';

export default function Layout(props) {

  const isMobile = window.screen.width < 600;

  //#region component state
  //const [activeMenu, setActiveMenu] = React.useState(null);
  //#endregion

  return (
    <div style={{
      backgroundColor: 'inherit',
    }}
    >
      {isMobile
        ? <NavMenuMobile applicationUser={props.applicationUser} logout={props.logout} activeMenu={"XXX"} /> 
        : <NavMenu applicationUser={props.applicationUser} logout={props.logout} activeMenu={"XXX"} />        
      }
      <Container tag="main" sx={{}} >
        {props.children}
      </Container>
    </div>
  );
}
