
//-------------------------------------------------------------------------------------------------
// RCN Application Home
//-------------------------------------------------------------------------------------------------
import { useState, useEffect } from "react";
// material ui
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Image from 'mui-image';
// rcn application

//-------------------------------------------------------------------------------------------------
// Component/View
//-------------------------------------------------------------------------------------------------

export default function HomeCheckItOut(props) {

  //#region react component/state
  const [viewReady, setViewReady] = useState(false);
  //const [LSS,] = useState(LocalStorageService());
  //const [favoriteTowns, setFavoriteTowns] = useState([]);
  //#endregion

  useEffect(() => {

    //setFavoriteTowns(JSON.parse(LSS.getItem("FavoriteTowns")));

    (async () => {

      //setTowns(await (new TownsClient()).getAllTowns());

      setViewReady(true);

    })();

  }, [] );

  return <Box sx={{pt:3}}>

    <Box sx={{}}>

      <Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
        <Image src="../assets/rcn.gop.png" width={60} />
        <h1>Republican Committees Network United</h1>
      </Box>

      <Typography sx={{mt:3}}>
        <p>The MassGOP is supported state-wide by Republican town and city committees (RTC/RCC) comprised of members of those towns and cities. This application (RCNU) was built to allow site visitors to learn about RTCs and for existing committee members to work together more effectively. </p>
        <p>You can learn more about the MassGOP by clicking this link
        <Button onClick={() => window.open('https://massgop.com/', '_blank', 'noopener,noreferrer')}>
          <span style={{ marginTop: 0 }}><strong>MASS-GOP</strong></span>
          </Button>
        </p>

      </Typography>

      <Stack sx={{ justifyContent: "center", alignItems: "center", }} hidden={true} direction="row" spacing={15}>
        <h3>COMMUNICATE</h3>
        <h3>COORDINATE</h3>
        <h3>COLLABORATE</h3>
        <h3>MEET</h3>
        <h3>NETWORK</h3>
      </Stack>

      <Paper sx={{ mt:3, p:3, minHeight:150 }}>
        <h3></h3>
      </Paper>
    </Box>

  </Box>;
}