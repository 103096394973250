
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
//#region material ui
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import AddLocationIcon from '@mui/icons-material/AddLocation';
import EmailIcon from '@mui/icons-material/Email';
//#endregion

import { DistrictsClient } from '../web-api-client.ts';

// modal member edit
function DeleteUserActionItem({ deleteUser, ...props }) {

  const [open, setOpen] = useState(false);

  return (
    <>
      <GridActionsCellItem {...props} onClick={() => setOpen(true)} />
      <Dialog fullScreen={"md"}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete this user?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            onClick={() => {
              setOpen(false);
              deleteUser();
            }}
            color="warning"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

//-------------------------------------------------------------------------------------------------
// State Admin Home
//-------------------------------------------------------------------------------------------------
export default function StateAdmin(props) {

  //#region react/component state
  const navigate = useNavigate();
  const [districts, setDistricts] = useState([]);
  const [districtSearch, setDistrictSearch] = useState(null);
  const [isChair, setIsChair] = useState(false);
  const { state } = useLocation();
  const [town, setTown] = useState(null);
  const [user, setUser] = useState(props.applicationUser);
  const [viewReady, setViewReady] = useState(false);
  const [value, setValue] = useState(0);
  const [formData, setFormData] = useState({ nameFirst: 'David', nameLast: '', numZipCode: '', addrRegistered: '', email: '', password: 'RTClogin.123' });
  const [searchQuery, setSearchQuery] = useState("");
  //#endregion

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChange = (event) => {
    setFormData(event.target.value);
  };

  // districts filtering...
  const SearchBar = ({ setSearchQuery }) => (
    <Box>
      <TextField autoFocus sx={{width:500}}
        id="search-bar"
        className="text"
        onChange={(e) => {
          setSearchQuery(e.target.value);
        }}
        label="search on district or chair name..."
        variant="outlined"
        //placeholder="Search..."
        size="small"
        value={searchQuery}
      />
      <IconButton type="submit" aria-label="search">
        <SearchIcon style={{ fill: "blue" }} />
      </IconButton>
    </Box>
  );
  const filterData = (query, data) => {
    if (!query) {
      return data;
    }
    else
    {
      return data.filter((d) => {
        return d.nameDistrict.toLowerCase().includes(query.toLowerCase()) ||
          d.nameChairMan.toLowerCase().includes(query.toLowerCase()) ||
          d.nameChairWoman.toLowerCase().includes(query.toLowerCase())
      })
    }
  }
  const dataFiltered = filterData(searchQuery, districts);

  useEffect(() => {

    (async () => {

      setDistricts(await (new DistrictsClient()).getAllDistricts());

      setViewReady(true);

    }) (/*iife*/);

  },[]);

  //-------------------------------------------------------------------------------------------------
  // component content
  //-------------------------------------------------------------------------------------------------
  return (true &&
    <Box>

      <h1>State Admin - {user.nameState}</h1>

      <Box sx={{ width: '100%', mt: 3 }}>

        <Tabs sx={{ borderBottom: 1, borderColor: 'divider' }}
          value={value} onChange={handleTabChange} aria-label="basic tabs example"
        >
          <Tab label="State Info" {...a11yProps(0)} />
          <Tab label="Districts" {...a11yProps(1)} />
        </Tabs>

        {/*state info*/}
        <CustomTabPanel value={value} index={0}>
          [TODO: all state-properties form data]
        </CustomTabPanel>

        {/*districts*/}
        <CustomTabPanel value={value} index={1}>

          <Box display="flex" justifyContent="flex-start" alignItems="space-between" sx={{mb:2}}>
            <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
            <Button style={{marginLeft: "auto"}} variant="outlined">Message Selected Administrator(s)...</Button>
          </Box>

          <div style={{ height: 400, width: '100%' }}>
            <DataGrid rows={dataFiltered}
              key={(x) => x.districtId}
              columns={columns}
              checkboxSelection
              getRowId={(x) => x.districtId}
              initialState={{ pagination: { paginationModel: { page: 0, pageSize: 25 }, }, }}
              pageSizeOptions={[5, 10, 25, 100]}
            />
          </div>

        </CustomTabPanel>

      </Box>

    </Box>
  )

  //-------------------------------------------------------------------
  // view helpers
  //-------------------------------------------------------------------
  async function createUser(id, psw, addr, zipcode, name) {

    //var foo = formData;

    //const pid = await (new PeopleClient()).createPerson({
    //  nameFirst: name, nameLast: name, numZipCode: zipcode, addrRegistered: addr, townId: 1,
    //});

    //const api = new UserClient();
    //await api.user_CreateUser({ districtId: pid, loginId: id, loginPassword: psw, loginEmail: id, });

    return;
  }
  async function updateStateSettings(formData) {

    //const pid = await (new PeopleClient()).createPerson({
    //  nameFirst: formData.nameFirst,
    //  nameLast: formData.nameLast,
    //  numZipCode: formData.numZipCode,
    //  addrRegistered: formData.addrRegistered,
    //  townId: state.townId,
    //});

    //const userApi = new UserClient();
    //await userApi.user_CreateUser({ districtId: pid, loginId: formData.email, loginPassword: formData.password, loginEmail:formData.email});

    //const stateApi = new StateClient();
    //await stateApi.createTownRtc({ townId:state.townId, districtId:pid});

    return;
  }
}

//-------------------------------------------------------------------------------------------------
// Grid/Table Stuff  
//-------------------------------------------------------------------------------------------------
const columns = [
  { field: 'districtId', headerName: 'ID', width: 70 },
  { field: 'nameDistrict', headerName: 'District', width: 260 },
  { field: 'nameChairMan', headerName: 'ChairMan', width: 150 },
  { field: 'nameChairWoman', headerName: 'ChairWoman', width: 150 },
  { field: 'nameAdministrator', headerName: 'Administrator', width: 150 },
  { field: 'numTownsCount', headerName: '#Active-Towns', width: 150 },
  { field: 'actions', type: 'actions', getActions: (params) =>
    [
      <DeleteUserActionItem label="Email" showInMenu icon={<EmailIcon />} closeMenuOnClick={false} />, //deleteUser={deleteUser(params.id)}
      <DeleteUserActionItem label="Activate" showInMenu icon={<AddLocationIcon />} closeMenuOnClick={false} /> //deleteUser={deleteUser(params.id)}
    ]
  },
];
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return { id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}`, };
}