
//-------------------------------------------------------------------------------------------------
// RCN - TownWelcomeDialog [InformationRequest]
//-------------------------------------------------------------------------------------------------

import { useState, useEffect, useRef } from "react";
import { useForm, } from 'react-hook-form';

//material ui
import { Alert, Button, Typography, Paper } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { AppBar, Toolbar } from '@mui/material';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
//import { FormHelperText } from '@mui/material';

// material icons
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';

// rcn application
import ApplicationStateService from '../services/ApplicationStateService';
import { MessageClient, } from '../web-api-client.ts';

// component
//-------------------------------------------------------------------------------------------------
export default function TownWelcomeDialog({ town, open, handleMessageClose }) {

  //#region react/form
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const handleInfoRequest = async (data) => {

    await (new MessageClient().postInfoRequest(
      {
        personIdSendTo: town.chairPersonFk === null ? town.districtPersonId : town.chairPersonFk,
        nameInfoRequest: data.msgName,
        emailInfoRequest: data.msgEmail,
        phoneInfoRequest: data.msgPhone,
        textInfoRequest: data.msgBody
      }));

    setHasErrors(false);
    setWasSent(true);
    setAlertMessage(`The information request was sent to the RTC chair... expect a call or email soon.`);
 };
  const handleError = (errors) => {
    setHasErrors(true);
  };
  const infoRequestOptions = {

    msgBody: {
      required: "Body is required",
      minLength: {
        value: 10,
        message: "Body must have at least 10 characters"
      }
    },
    msgName: { required: "Name is required" },

    msgEmail: {
      required: "Email is required",
      pattern: {
        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: 'Please enter a valid email',
      },
    },

    msgPhone: {
      required: "Phone is required",
      pattern: {
        value: /^[+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
        message: 'Please enter a valid phone number',
      },
      minLength: {
        value: 10,
        message: "Phone must have at least 10 characters"
      }
    }
  };
  //#endregion

  //#region react/component state
  const [hasErrors, setHasErrors] = useState(false);
  const [wasSent, setWasSent] = useState(false);
  const [viewReady, setViewReady] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertStatus, ] = useState("info");
  //#endregion

  const myRef = useRef(null);

  useEffect(() => {

    if (town.chairPersonFk === null) {
      setAlertMessage(`The district committee chair from ${town.nameDistrict} will get back as soon as possible.`);
    } else {
      setAlertMessage(`The ${town.nameTown} RTC chair, ${town.nameChairFull}, will get back as soon as possible.`);
    }

    (async () => {

      setViewReady(true);

    })(/*iife*/);

  }, []);

  //#region event handlers
  //#endregion

  // content
  //-------------------------------------------------------------------------------------------------
  return (

    <Dialog fullWidth maxWidth="lg" open={open} onClose={handleMessageClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">

      <AppBar sx={{ position: 'relative' }}>

        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleMessageClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            REQUEST RTC INFORMATION - {town.nameTown}/{town.nameDistrict}
          </Typography>
        </Toolbar>

      </AppBar>

      <DialogTitle id="alert-dialog-title">Complete required data and click SEND</DialogTitle>

      <DialogContent>

        <DialogContentText id="alert-dialog-description">

          <Alert severity={alertStatus}>{alertMessage}</Alert>

          {hasErrors && <Alert sx={{ mt: 1 }} severity="error">Please correct/complete data and try SEND again</Alert>}

          <Paper ref={myRef} component="form" noValidate autoComplete="off" sx={{ mt: 3, p: 1 }} onSubmit={handleSubmit(handleInfoRequest, handleError)}>

            <TextField sx={{ mt: 1 }} name="msgSubject" label="Message Subject" fullWidth variant="outlined"
              inputProps={{ readOnly: true }} {...register('msgSubject', infoRequestOptions.msgSubject)} type="text"
              helperText={errors?.msgSubject && errors.msgSubject.message}
              value={`Information Request - ${town.nameTown}`}
            />

            <TextField sx={{ mt: 3 }} name="msgBody" label="Message Body" fullWidth variant="outlined"
              inputProps={{ readOnly: wasSent, maxLength: 500, }} {...register('msgBody', infoRequestOptions.msgBody)} type="text"
              helperText={errors?.msgBody && <span style={{ color: 'red' }}>{errors.msgBody.message}</span>}
              multiline rows={4}
            />

            <Stack sx={{ mt: 2 }} direction="row" spacing={1}>
              <TextField name="msgName" label="Name" fullWidth variant="outlined"
                inputProps={{ readOnly: wasSent }} {...register('msgName', infoRequestOptions.msgName)} type="text"
                helperText={errors?.msgName && <span style={{ color: 'red' }}>{errors.msgName.message}</span>}
              />
              <TextField name="msgEmail" label="Email" fullWidth variant="outlined"
                inputProps={{ readOnly: wasSent }} {...register('msgEmail', infoRequestOptions.msgEmail)} type="email"
                helperText={errors?.msgEmail && <span style={{ color: 'red' }}>{errors.msgEmail.message}</span>}
              />
              <TextField sx={{}}  name="msgPhone" label="Phone" fullWidth variant="outlined"
                inputProps={{ readOnly: wasSent }} {...register('msgPhone', infoRequestOptions.msgPhone)} type="text"
                helperText={errors?.msgPhone && <span style={{color:'red'}}>{errors.msgPhone.message}</span>}
              />
            </Stack>

            <Button disabled={wasSent} type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              SEND
            </Button>

          </Paper>

        </DialogContentText>

      </DialogContent>

      <DialogActions>
        <Button onClick={handleMessageClose}>CANCEL</Button>
      </DialogActions>

    </Dialog>

  );
}