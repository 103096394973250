
import React, { useState, useEffect } from 'react';
import { Routes, Route, Link, useNavigate } from 'react-router-dom';

import { Button, Box, TextField, Typography } from '@mui/material';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Alert from "@mui/material/Alert";

export default function LogInOut(props) {

  const navigate = useNavigate();

  //#region react component/state
  const [loginError, setLoginError] = useState(false);
  //#endregion

  return (

    <Box sx={{ mt: 3 }}>

      <h3>Committee Member Login/Logout</h3>

      {loginError && <Alert sx={{my:3}}>Could not login with that email & password</Alert>}

      <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={(event) => {

        event.preventDefault();
        const data = new FormData(event.currentTarget);

        if (props.loginHandler !== null) {
          Promise.resolve(props.loginHandler(data.get("email"), data.get("password"))).then((hrc) => {
            if (hrc === true) {
              navigate("/Home", { state: {} });
            }
            else {
              setLoginError(true);
            }
          });
        }
      }}
      >
        <TextField margin="normal" required fullWidth id="email" label="Email Address" name="email" autoComplete="email" autoFocus />
        <TextField margin="normal" required fullWidth name="password" label="Password" type="password" id="password" autoComplete="current-password" />

        <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />

        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          Login
        </Button>

        <Typography sx={{ mt: 3 }} variant="h5" gutterBottom>
          Change Password (enter current userid/password above)
        </Typography>

        <TextField margin="normal" fullWidth name="newpassword1" label="New Password" type="password" id="newpassword1" autoComplete="current-password" />
        <TextField margin="normal" fullWidth name="newpassword2" label="New Password" type="password" id="newpassword2" autoComplete="current-password" />

        <Button sx={{ mt: 3, mb: 2 }} disabled={true} type="submit" fullWidth variant="outlined">
          Change Password & Login
        </Button>

      </Box>

    </Box>
  );

}
