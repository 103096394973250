
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { StateClient, UserClient, PeopleClient } from '../web-api-client.ts';

//-------------------------------------------------------------------------------------------------
// State Admin Home
//-------------------------------------------------------------------------------------------------
export default function TownAdmin(props) {

  const [town, setTown] = useState(null);
  const [user, setUser] = useState(props.applicationUser);
  const [viewReady, setViewReady] = useState(false);
  const [formData, setFormData] = useState({ nameFirst: 'David', nameLast: '', numZipCode: '', addrRegistered: '', email: '', password:'RTClogin.123' });

  useEffect(() => {

    (async () => {

        setViewReady(true);

    })();

  }, []);

  return (viewReady && props &&
    <Box>

      <h1>Town RTC Admin - {props.applicationUser.nameTown}</h1>

      <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={(event) => {

        event.preventDefault();
        updateTownSettings(formData);
      }}
      >
        <Box sx={{ mt: 1 }}><p>* - all properties required</p></Box>

        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          UPDATE TOWN SETTINGS
        </Button>

      </Box>

    </Box>
  )

  //-------------------------------------------------------------------
  // view helpers
  //-------------------------------------------------------------------

  async function updateTownSettings(formData) {

    //const pid = await (new PeopleClient()).createPerson({
    //  nameFirst: formData.nameFirst,
    //  nameLast: formData.nameLast,
    //  numZipCode: formData.numZipCode,
    //  addrRegistered: formData.addrRegistered,
    //  townId: state.townId,
    //});

    //const userApi = new UserClient();
    //await userApi.user_CreateUser({ personId: pid, loginId: formData.email, loginPassword: formData.password, loginEmail:formData.email});

    //const stateApi = new StateClient();
    //await stateApi.createTownRtc({ townId:state.townId, personId:pid});

    return;
  }
}