
//-------------------------------------------------------------------------------------------------
// RCN - District Home Page
//-------------------------------------------------------------------------------------------------
import { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";

// rcn application
import ApplicationStateService from '../services/ApplicationStateService';
import UserLocation from '../components/common/UserLocation';
import SpecialPermissions from '../components/common/SpecialPermissions';
import MessageMembersButton from '../components/common/MessageMembersButton';
import DistrictTownOnboarding from '../components/DistrictTownOnboarding';

// material ui
import { Alert, Button, Box, Link, Typography,  } from '@mui/material';
import { Tabs, Tab } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { DataGrid, GridActionsCellItem, gridClasses } from '@mui/x-data-grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';

// material ui icons
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import AddLocationIcon from '@mui/icons-material/AddLocation';
import EmailIcon from '@mui/icons-material/Email';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

//#region towns table
const columns = [

  {
    field: 'nameTown', headerName: 'City/Town', width: 175, headerClassName: 'super-app-theme--header', renderCell: (cellValues) => {
      return <Link component={RouterLink}
        to={{ pathname: "/Town", search: "?townId=" + cellValues.row.townId, state: { /*notused*/ } }}>
        {cellValues.row.nameTown}
      </Link>;
    }
  },
  { field: 'nameChairFull', headerName: 'Chair', width: 225, headerClassName: 'super-app-theme--header' },
  { field: 'textDateMeet', headerName: 'Meeting Date', width: 300, headerClassName: 'super-app-theme--header' },
  { field: 'codeStatus', headerName: 'Status', width: 100, align: "center", headerClassName: 'super-app-theme--header' },
  { field: 'numMembersCount', headerName: '#Members', width: 100, align: "center", headerClassName: 'super-app-theme--header' },
  { field: 'numEventsCount', headerName: '#Events', width: 100, align: "center", headerClassName: 'super-app-theme--header' },
  { field: 'numMessagesCount', headerName: '#Messages', width: 100, align: "center", headerClassName: 'super-app-theme--header' },
  {
    field: 'actions', headerName: 'Actions', type: 'actions', flex: 1, align: "right", headerAlign: "right", headerClassName: 'super-app-theme--header', getActions: (params) =>
      [
        <GridActionsCellItem disabled={true} label={"nothing to see here... yet"} showInMenu={true} />,
      ]
  },
];
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return { id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}`, };
}
//#endregion

//-------------------------------------------------------------------------------------------------
// component
//-------------------------------------------------------------------------------------------------
export default function DistrictHome(props) {

  const navigate = useNavigate();
  const townCommitteeURL = process.env.REACT_APP_URL_TOWN_COMMITTEE; // don't expect intellisense here!!

  //#region react/component state
  const querySearch = useLocation().search;

  const [appStateService,] = useState(props.appStateService);
  const [person, setPerson] = useState(null);
  const [district, setDistrict] = useState(null);
  const [towns, setTowns] = useState([]);
  const [townSearch, setTownSearch] = useState(null);
  const [isChair, setIsChair] = useState(false);
  const { state } = useLocation();
  const [user, setUser] = useState(props.applicationUser);
  const [viewReady, setViewReady] = useState(false);
  const [value, setValue] = useState(0);
  const [formData, setFormData] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [hideTowns, setHideTowns] = useState(true);
  const [enableOnboarding, setEnableOnboarding] = useState(false);
  const [selectedIds, setSelectedIds] = useState(null);
  const [showOnboarding, setShowOnboarding] = useState(false);
  //#endregion

  //#region event handlers
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChange = (event) => {
    setFormData(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
  };
  const getSelectedNames = () => {

    let names = "";

    if (selectedIds) {
      selectedIds.forEach((id, x) => {
        names = names + towns.find(t => t.townId === id).nameChairFull
        if (x + 1 < selectedIds.length) { names = (names + ", ") }
      });
    }

    return names;
  }
  const getSelectedPeople = () => {

    let ids = [];

    if (selectedIds) {
      // for each district id, get the chair id
      selectedIds.forEach((id, x) => {
        ids.push(towns.find(t => t.townId === id).chairPersonFk);
      });
    }

    return ids;
  }
  const disableOnboarding = () => {

    if (!selectedIds) return false; // allow new town
    if (selectedIds.length > 1) return true;

    const town = towns.find((t) => t.townId === selectedIds[0]);

    if (town && town.isActive)
    {
      return true; // disable it
    }
    else
    {
      return false; // enable it
    }
  }
  //#endregion

  //#region towns filtering
  const SearchBar = ({ setSearchQuery }) => (
    <Box>
      <TextField autoFocus sx={{ width: 500 }}
        type="search"
        id="search-bar"
        className="text"
        onChange={(e) => {
          setSearchQuery(e.target.value);
        }}
        label="search on town or chair name..."
        variant="outlined"
        //placeholder="Search..."
        size="small"
        value={searchQuery}
      />
      <IconButton type="submit" aria-label="search">
        <SearchIcon style={{ fill: "blue" }} />
      </IconButton>
    </Box>
  );
  const filterData = (query, data) => {

    if (query !== "") {
      return data.filter((d) => {
        return d.nameTown.toLowerCase().includes(query.toLowerCase())
      })
    }
    // or do this (when checkbox changed)
    return data.filter((d) => { return d.codeStatus === "ACTIVE" || !hideTowns });
  }
  const dataFiltered = filterData(searchQuery, towns);
  const handleHideTowns = (event) => {

    setHideTowns(event.target.checked);
    filterData("", towns);

  };
  //#endregion

  useEffect(() => {

    let params = new URLSearchParams(querySearch);
    let searchTerm = params.get('districtId');
    if (!searchTerm) searchTerm = props.applicationUser.districtId;

    (async () => {

      const person = await appStateService.person(); setPerson(person);
      const district = await appStateService.district(searchTerm); setDistrict(district);
      const towns = await appStateService.towns(searchTerm); setTowns(towns);

      setFormData(district);
      setViewReady(true);

    })(/*iife*/);

  }, []);

  //-------------------------------------------------------------------------------------------------
  // content
  //-------------------------------------------------------------------------------------------------
  return (viewReady &&

    <Box sx={{ mt: 3 }}>

      <Typography color="red"><h3>Republican Committees Network - District View</h3></Typography>
      <h1>District: Massachusetts {'>'} {district ? district.nameDistrict : user.nameDistrict}</h1>
      <SpecialPermissions page="DISTRICT" id={district.personIdChair} applicationUser={user} />
      <UserLocation appUser={props.applicationUser} />
      <Typography style={{}} variant="h6" component="h6">
        <strong>District Committee Chairs:</strong> {district.nameChairManFull + ", " + district.nameChairWomanFull}
        <MessageMembersButton buttonText="MESSAGE DISTRICT CHAIRS" sendTo={[district.personIdChairMale, district.personIdChairFemale]} iconOnly={true} sendToName={`${district.nameChairManFull}, ${district.nameChairWomanFull}`} />
      </Typography>

      <Box sx={{ width: '100%', mt: 1 }}>

        <Tabs sx={{ mt: 1, mb: 3, borderBottom: 1, borderBottom: 1, borderColor: 'divider' }}
          value={value} onChange={handleTabChange} aria-label="basic tabs example"
        >
          <Tab label="District" {...a11yProps(0)} />
          <Tab label="Town/City(s)" {...a11yProps(1)} />
        </Tabs>

        { /* District info*/}
        <CustomTabPanel value={value} index={0}>
          <Box component="form" noValidate sx={{ mt: 0 }} onSubmit={handleSubmit}>

            <TextField required variant="standard" margin="normal" fullWidth id="nameChairManFull" label="District Chair" name="nameChairManFull" autoFocus
              value={formData.nameChairManFull} onChange={handleChange}
              InputProps={{
                endAdornment: <InputAdornment position="end"><EmailIcon /></InputAdornment>
              }}
            />
            <TextField required variant="standard" margin="normal" fullWidth id="nameChairWomanFull" label="District Co-Chair" name="nameChairWomanFull"
              value={formData.nameChairWomanFull} onChange={handleChange}
              InputProps={{
                endAdornment: <InputAdornment position="end"><EmailIcon /></InputAdornment>
              }}
            />
            <TextField required variant="standard" margin="normal" fullWidth id="codeStatus" label="District Status" name="textDateMeet"
              value={formData.codeStatus} onChange={handleChange}
            />

            <Box sx={{ mt: 1 }}><p></p></Box>

            <Button disabled={true} type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              SAVE CHANGES
            </Button>

          </Box>
        </CustomTabPanel>

        { /* Districts */ }
        <CustomTabPanel value={value} index={1}>

          <Box sx={{ mb: 2, display: 'flex' }}>

            <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />

            <Button sx={{ ml: 22 }} variant="outlined"
              onClick={() => setShowOnboarding(true)}
              disabled={disableOnboarding()}
            >
              {`TOWN ${selectedIds ? "" : "[new]"} ONBOARDING...`}
            </Button>
            {showOnboarding &&
              <DistrictTownOnboarding

                refreshParent={() => {

                  (async () => {

                    const towns = await await appStateService.towns(district.districtId);
                    setTowns(towns);

                  })(/*iife*/);

                }}

                isOpen={showOnboarding}
                setOpen={() => setShowOnboarding(false)}
                name={"Configure New Town"}
                id={selectedIds ? selectedIds[0] : null}
                appUser={props.applicationUser}
              >
              </DistrictTownOnboarding>
            }
            <MessageMembersButton disabled={selectedIds} buttonText="MESSAGE SELECTED CHAIR(S)" sendTo={getSelectedPeople()} iconOnly={false} sendToName={getSelectedNames()} />

          </Box>

          <Box sx={{ width: '100%',
            [`.${gridClasses.cell}.cold`]: { },
            [`.${gridClasses.cell}.hot`]: { color: 'green', },
          }}>

            <DataGrid sx={{

              '& .super-app-theme--header': { backgroundColor: '#bbdefb', },

              '.MuiTablePagination-displayedRows': {
                'margin-top': '1em',
                'margin-bottom': '1em'
              },
              '.MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel': {
                'margin-top': '1em',
                'margin-bottom': '1em'
              }
            }} 
              density="compact"
              rows={dataFiltered} columns={columns} key={(x) => x.townId}
              checkboxSelection
              getRowId={(x) => x.townId}
              getCellClassName={(params) => {
                if (params.field === 'codeStatus' && params.value != null) {
                  return params.value === "ACTIVE" ? 'hot' : 'cold';
                }
                return '';
              }}
              initialState={{ pagination: { paginationModel: { page: 0, pageSize: 25 }, }, }}
              pageSizeOptions={[5, 10, 25, 100]}
              onRowSelectionModelChange={(ids) => {

                setSelectedIds(ids.length > 0 ? ids : null);

                if (ids.length === 1) {
                  const selectedRowData = towns.filter((row) => row.townId === ids[0])[0];
                  setEnableOnboarding(!selectedRowData.isActive);
                }
                else {
                  setEnableOnboarding(false);
                }

              }} />

          </Box>

          <FormControlLabel control={<Checkbox defaultChecked />}
            value={hideTowns}
            label={<Typography>Hide towns that are not <span style={{ color: 'green' }} ><strong>ACTIVE</strong></span> in the RC.Network</Typography>}
            onClick={handleHideTowns}
          />

        </CustomTabPanel>

      </Box>

    </Box>
  )
}
