
import React, { } from 'react';
// material ui
import { Alert, } from '@mui/material';

export default function SpecialPermissions(props) {

  //#region component state
  //const [activeMenu, setActiveMenu] = React.useState(null);
  //#endregion

  let userPermissions = "none";

  if (props.page === "NATIONAL" && props.id === props.applicationUser.personId) {
    userPermissions = "NATIONAL-ADMIN";
  }

  if (props.page === "STATE" && props.id === props.applicationUser.personId) {
    userPermissions = "STATE-ADMIN";
  }

  if (props.page === "DISTRICT" && props.id === props.applicationUser.personId) {
    userPermissions = "DISTRICT-ADMIN";
  }

  if (props.page === "TOWN" && props.id === props.applicationUser.personId) {
    userPermissions = "TOWN-ADMIN";
  }

  return (
    <Alert sx={{ mb: 1 }} severity="info">Special Permission Activated: {userPermissions}</Alert>
  );
}
