
//-------------------------------------------------------------------------------------------------
// RCN - Reuseable Send-Message Component
//-------------------------------------------------------------------------------------------------

import React, { } from 'react';
//rcn application
import NewMessageDialog from '../MessageDialog';
//material ui
import { Alert, Button, Box, Link, Typography, Tooltip } from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';

//-------------------------------------------------------------------------------------------------
// component
//-------------------------------------------------------------------------------------------------
export default function MessageMembersButton(props) {

  //#region component state
  //const [activeMenu, setActiveMenu] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  //#endregion

  //#region component event handlers
  const handleClickOpen = () => {

    if (props.sendTo.length > 0) setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  //#endregion

  //-------------------------------------------------------------------------------------------------
  // component content
  //-------------------------------------------------------------------------------------------------
  return (
    <>

      {!props.iconOnly &&
        <Button disabled={!props.disabled} style={{ marginLeft:"auto" }} variant="outlined" onClick={handleClickOpen}>
          {props.buttonText}...
        </Button>
      }

      {props.iconOnly &&
        <Tooltip title={props.buttonText} placement="right-start">

          <IconButton aria-label="delete" size="medium" onClick={handleClickOpen}>
            <EmailTwoToneIcon sx={{ ml: 0 }} fontSize="inherit" />
          </IconButton>

        </Tooltip>
      }

      {open && <NewMessageDialog open={open} handleMessageClose={handleClose} sendTo={props.sendTo} sendToName={props.sendToName} /> }
    </>
  );
}
