
//-------------------------------------------------------------------------------------------------
// RCN - Message Center Component
//-------------------------------------------------------------------------------------------------

//#region imports
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
// rcn application
import ApplicationStateService from '../services/ApplicationStateService';
import UserLocation from '../components/common/UserLocation';
import { MessageClient } from '../web-api-client.ts';
//material ui
import { Alert, Button, Box, Link } from '@mui/material';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { DataGrid, GridActionsCellItem, gridPageCountSelector, GridPagination, useGridApiContext, useGridSelector, } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import AddLocationIcon from '@mui/icons-material/AddLocation';
import EmailIcon from '@mui/icons-material/Email';
import MuiPagination from '@mui/material/Pagination';
//#endregion  react/component state

//#region messages table
const columns = [
  { field: 'messageId', headerName: 'ID', width: 70, hide: true },
  { field: 'messageSubject', headerName: 'Subject', width: 200 },
  { field: 'messageFrom', headerName: 'From', width: 200 },
  { field: 'whenSent', headerName: 'WhenSent', width: 200 },
];
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return { id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}`, };
}
function DeleteUserActionItem({ deleteUser, ...props }) {

  const [open, setOpen] = useState(false);

  return (
    <>
      <GridActionsCellItem {...props} onClick={() => setOpen(true)} />
      <Dialog fullScreen={"md"}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete this user?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            onClick={() => {
              setOpen(false);
              deleteUser();
            }}
            color="warning"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
//#endregion people table

//-------------------------------------------------------------------------------------------------
// Component
//-------------------------------------------------------------------------------------------------
export default function MessageCenter(props) {

  //#region react/component state
  const [messages, setMessages] = useState([]);
  const [viewReady, setViewReady] = useState(false);
  const [value, setValue] = useState(0);
  const [formData, setFormData] = useState({  });
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRows, setSelectedRows] = useState(null);
  //#endregion

  //#region event handlers
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChange = (event) => {
    setFormData(event.target.value);
  };
  const handleRowClick = async (event) => {
    await (new MessageClient()).putMessage(8)
  };
  //#endregion event handlers

  //#region people navigation & filtering
  const SearchBar = ({ setSearchQuery }) => (
    <Box>
      <TextField autoFocus sx={{ width: 500 }}
        id="search-bar"
        className="text"
        onChange={(e) => {
          setSearchQuery(e.target.value);
        }}
        label="search on message subject or body..."
        variant="outlined"
        //placeholder="Search..."
        size="small"
        value={searchQuery}
      />
      <IconButton type="submit" aria-label="search">
        <SearchIcon style={{ fill: "blue" }} />
      </IconButton>
    </Box>
  );

  const filterData = (query, data, type) => {

    if (!query) {
      return data ? data.filter(x => type === 'I' ? !x.wasRead : x.wasRead) : data;
    }
    else {
      return data.filter((d) => {
        return d.messageSubject.toLowerCase().includes(query.toLowerCase()) ||
          d.messageSubject.toLowerCase().includes(query.toLowerCase()) ||
          d.messageSubject.toLowerCase().includes(query.toLowerCase())
      })
    }
  }
  const dataFilteredI = filterData(searchQuery, messages, 'I');
  const dataFilteredR = filterData(searchQuery, messages, 'R');
  const handleRowSelectionModelChange = (params) => {
    setSelectedRows(params);
  };
  function Pagination({ page, onPageChange, className }) {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <MuiPagination display="flex-box" sx={{ '& > .MuiPagination-ul': { display:'inline-flex', } }} style={{   }}
        color="primary"
        className={className}
        count={pageCount}
        page={page + 1}
        onChange={(event, newPage) => { onPageChange(event, newPage - 1); }}
        labelDisplayedRows={({ from, to, count, page }) => {
          return defaultLabelDisplayedRows({ from, to, count, page }) // never called
        }}
      />
    );
  }
  function CustomPagination(props) {
    return <GridPagination style={{ display: "inline-flex" }} ActionsComponent={Pagination} {...props} />;
  }
  const defaultLabelDisplayedRows: React.FC<LabelDisplayedRowsArgs> = (props): React.ReactElement<any, any> => {
    return (
      <>
        <p>
          <b> Mostrando itens {" "}
            <span style={{ color: "#FFB03A" }}>
              {props.from > 0 && props.from < 10 ? `0${props.from}-0${props.to}` : `${props.from}-${props.to}`}
            </span>
          </b>
          <p style={{ padding: 0, margin: 0 }}>total de {props.count} itens</p>
        </p>
      </>
    )
  }
  //#endregion people navigation & filtering

  useEffect(() => {

    (async () => {

      setMessages(await (new MessageClient()).getMessages());
      setViewReady(true);

    }) (/*iife*/);

  }, []);

  //-------------------------------------------------------------------------------------------------
  // Component Content
  //-------------------------------------------------------------------------------------------------
  return (

    <Box sx={{ mt: 3 }}>

      <h1>Message Center</h1>
      <Alert sx={{ mb: 1 }} severity="info">Special Permission Activated: state-admin, district-admin, town-admin</Alert>
      <UserLocation appUser={props.applicationUser} />

      <Box sx={{ width: '100%', mt: 2 }}>

        <Tabs sx={{ borderBottom: 1, borderColor: 'divider' }} value={value} onChange={handleTabChange} >
          <Tab label="InBox" {...a11yProps(0)} />
          <Tab label="Previously Seen" {...a11yProps(1)} />
          <Tab label="Sent" {...a11yProps(2)} />
        </Tabs>

        {/* Messages - InBox */}
        <CustomTabPanel value={value} index={0}>

          <Box display="flex" justifyContent="flex-start" alignItems="space-between" sx={{ mb: 2 }}>
            <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
          </Box>

          <DataGrid sx={{ '& .super-app-theme--header': { backgroundColor: '#bbdefb', } }}
            rows={dataFilteredI}
            onRowClick={handleRowClick}
            key={(x) => x.messageId}
            columns={columns}
            checkboxSelection
            getRowId={(x) => x.messageId}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  messageId: false,
                },
              },
              pagination: { paginationModel: { page: 0, pageSize: 50 }, },
            }}
            pagination={true}
            pageSizeOptions={[50]}
          />

        </CustomTabPanel>

        {/* Messages - Previously Seen */}
        <CustomTabPanel value={value} index={1}>
          <Box display="flex" justifyContent="flex-start" alignItems="space-between" sx={{ mb: 2 }}>
            <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
          </Box>

          <DataGrid sx={{ '& .super-app-theme--header': { backgroundColor: '#bbdefb', } }}
            rows={dataFilteredR}
            onRowClick={handleRowClick}
            key={(x) => x.messageId}
            columns={columns}
            checkboxSelection
            getRowId={(x) => x.messageId}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  messageId: false,
                },
              },
              pagination: { paginationModel: { page: 0, pageSize: 50 }, },
            }}
            pagination={true}
            pageSizeOptions={[50]}
          />

        </CustomTabPanel>

        {/* Messages - Send */}
        <CustomTabPanel value={value} index={2}>
        </CustomTabPanel>

      </Box>  

    </Box>
  )
}