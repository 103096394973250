
// this service will provide all application state data objects

import axios from "axios";
import { TownsClient, DistrictsClient, StateClient } from '../web-api-client.ts';

export default function LocalStorageService(storageName) {

  return {

    setItem: function (items) {

      window.localStorage.setItem(storageName, JSON.stringify(items));

    },

    getItem: function () {

      const foo = window.localStorage.getItem(storageName);
      if (foo != null) {
        return JSON.parse(foo);
      }

      return null;
    }

  }
};