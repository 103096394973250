
import React, { } from 'react';

export default function UserLocation(props) {

  //#region component state
  //const [activeMenu, setActiveMenu] = React.useState(null);
  //#endregion

  return (
    <>
      <p>Your Location: {props.appUser.nameState} {'>'} {props.appUser.nameDistrict} {'>'} {props.appUser.nameTown}</p>
    </>
  );
}
