
//-------------------------------------------------------------------------------------------------
// RCN - National Home Page
//-------------------------------------------------------------------------------------------------
import { useState, useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
// rcn application
import ApplicationStateService from '../services/ApplicationStateService';
import UserLocation from '../components/common/UserLocation';
import SpecialPermissions from '../components/common/SpecialPermissions';
import MessageMembersButton from '../components/common/MessageMembersButton';
// material ui
import { Alert, Button, Box, Link, Typography, Grid } from '@mui/material';
import { Tabs, Tab } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { DataGrid, GridActionsCellItem, useGridApiRef } from '@mui/x-data-grid';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';

// material ui icons
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import AddLocationIcon from '@mui/icons-material/AddLocation';
import EmailIcon from '@mui/icons-material/Email';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

//#region states table
const columns = [
  { field: 'stateId', headerName: 'ID', width: 70, headerClassName: 'super-app-theme--header' },
  {
    field: 'nameState', headerName: 'State', width: 200, headerClassName: 'super-app-theme--header', renderCell: (cellValues) => {
      return <Link component={RouterLink}
        to={{ pathname: "/statehome", search: "?stateId=" + cellValues.row.stateId, state: { /*notused*/ } }}>
        {cellValues.row.nameState}
      </Link>;
    }
  },
  { field: 'nameOwner', headerName: 'Committee Chair', width: 200, headerClassName: 'super-app-theme--header' },
  { field: 'nameAdmin', headerName: 'Administrator', width: 200, headerClassName: 'super-app-theme--header' },
  { field: 'numTownCount', headerName: '#Towns', width: 100, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', },
  { field: 'numRtcCount', headerName: '#Committes', width: 100, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', },
  { field: 'numMemberCount', headerName: '#Members', width: 275, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', },
  {
    field: 'actions', headerName: 'Actions', flex: 1, headerAlign: 'right', align:'right',  type: 'actions', getActions: (params) =>
      [
        <StateRowActionItem label="Email" showInMenu icon={<EmailIcon />} closeMenuOnClick={false} />, 
        <StateRowActionItem label="Activate" showInMenu icon={<AddLocationIcon />} closeMenuOnClick={false} />,
        <StateRowActionItem label="Goto State" showInMenu icon={<AddLocationIcon />} closeMenuOnClick={false} />
      ]
  },
];
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return { id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}`, };
}
function StateRowActionItem({ deleteUser, ...props }) {

  const [open, setOpen] = useState(false);

  return (
    <>
      <GridActionsCellItem {...props} onClick={() => setOpen(true)} />
      <Dialog fullScreen={"md"}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete this user?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            onClick={() => {
              setOpen(false);
              deleteUser();
            }}
            color="warning"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
//#endregion

//-------------------------------------------------------------------------------------------------
// React Component
//-------------------------------------------------------------------------------------------------
export default function USAHome(props) {

  //#region react/component state
  const [appStateService,] = useState(props.appStateService);
  const [fooRows,setFooRows] = useState([]);
  const [user,] = useState(props.applicationUser);
  const [state, setState] = useState(null);
  const [states, setStates] = useState(null);
  const [viewReady, setViewReady] = useState(false);
  const [value, setValue] = useState(0);
  const [formData, setFormData] = useState({ nameOwner: 'David K Anderson', emailOwner: 'davida@rcnetwork.org', });
  const [searchQuery, setSearchQuery] = useState("");
  //#endregion

  useEffect(() => {

    (async () => {

      setState(await appStateService.state("DC"));
      setStates(await appStateService.states());

      setViewReady(true);

    })(/*iife*/);

  }, []);

  //#region event handlers
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChange = (event) => {

    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
  };
  //#endregion

  //#region states filtering/selection
  const SearchBar = ({ setSearchQuery }) => (
    <Box>
      <TextField autoFocus sx={{ width: 500 }}
        type="search"
        id="search-bar"
        className="text"
        onChange={(e) => {
          setSearchQuery(e.target.value);
        }}
        label="search on state or chair name..."
        variant="outlined"
        //placeholder="Search..."
        size="small"
        value={searchQuery}
      />
      <IconButton type="submit" aria-label="search">
        <SearchIcon style={{ fill: "blue" }} />
      </IconButton>
    </Box>
  );
  const filterData = (query, data, type) => {
    if (!query) {
      return data ? data.filter(x => type === 'A' ? x.personIdChair : !x.personIdChair ) : data;
    }
    else {
      return data.filter((d) => {
        return d.nameState.toLowerCase().includes(query.toLowerCase()) ||
          d.nameState.toLowerCase().includes(query.toLowerCase()) ||
          d.nameState.toLowerCase().includes(query.toLowerCase())
      })
    }
  }
  const dataFilteredA = filterData(searchQuery, states, 'A');
  const dataFilteredF = filterData(searchQuery, states, 'F');
  //#endregion states filtering

  let apiRef = useGridApiRef();

  //-------------------------------------------------------------------------------------------------
  // component View
  //-------------------------------------------------------------------------------------------------
  return (viewReady &&

    <Box sx={{ mt: 3, }}>

      <Typography color="red"><h3>Republican Committees Network - National View (RNC)</h3></Typography>
      <SpecialPermissions page="NATIONAL" id={state.personIdChair} applicationUser={user} />
      <UserLocation appUser={props.applicationUser} />
      <Typography style={{  }} variant="h6" component="h6">
        <strong>National Committee Chairs:</strong> {state.nameChair}, Lara Trump
        <MessageMembersButton buttonText="MESSAGE NATIONAL CHAIR" sendTo={[1]} iconOnly={true} />
      </Typography>

      <Box sx={{ width: '100%', mt: 1 }}>

        <Tabs sx={{ mt: 1, mb: 3, borderBottom: 1, borderColor: 'divider' }} value={value} onChange={handleTabChange} >
          <Tab label="RNC" {...a11yProps(0)} />
          <Tab label="Active States" {...a11yProps(1)} />
          <Tab label="Future States" {...a11yProps(2)} />
        </Tabs>

        {/* USA Tab */}
        <CustomTabPanel value={value} index={0}>

          <Button endIcon={<ArrowOutwardIcon />} onClick={() => window.open('https://www.gop.com/', '_blank', 'noopener,noreferrer')}>
            Visit the National GOP-Committee Website
          </Button>

          <Box sx={{ mt: 3, flexGrow: 1 }}>
            <Grid container spacing={2}>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="my-input">Committee Chair(s)</InputLabel>
                  <Input id="my-input" aria-describedby="my-helper-text" value={'Michael Whatley, Lara Trump'} />
                  <FormHelperText id="my-helper-text">{''}</FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="my-input">Address</InputLabel>
                  <Input id="my-input" aria-describedby="my-helper-text" value={'310 First Street SE, Washington, D.C., U.S.'} />
                  <FormHelperText id="my-helper-text">{''}</FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="my-input">Product Owner</InputLabel>
                  <Input id="my-input" aria-describedby="my-helper-text"
                    value={'David K Anderson'}
                    endAdornment={<InputAdornment position="end"><EmailIcon/></InputAdornment>}
                  />
                  <FormHelperText id="my-helper-text">{''}</FormHelperText>
                </FormControl>
              </Grid>

            </Grid>
          </Box>

          {false && <Box component="form" noValidate onSubmit={handleSubmit}>

            <TextField required variant="standard" margin="normal" fullWidth id="nameOwner" label="Application-Owner (USA)" name="nameOwner" autoFocus
              value={formData.nameOwner} onChange={handleChange}
            />
            <TextField required variant="standard" margin="normal" fullWidth id="emailOwner" label="Application-Owner-Email" name="emailOwner"
              value={formData.emailOwner} onChange={handleChange}
            />
            <TextField required variant="standard" margin="normal" fullWidth id="emailAdmin" label="Administrator-Email" name="emailOwner"
              value={formData.emailOwner} onChange={handleChange}
            />

            <Box sx={{ mt: 1 }}><p></p></Box>

            <Button disabled={true} type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              SAVE CHANGES
            </Button>

          </Box>}

        </CustomTabPanel>

        {/* States Tab (active) */}
        <CustomTabPanel value={value} index={1}>

          <Box display="flex" justifyContent="flex-start" alignItems="space-between" sx={{ mb: 2 }}>
            <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
            <MessageMembersButton buttonText="MESSAGE SELECTED STATE CHAIR(S)" sendTo={fooRows} />
          </Box>

          <DataGrid sx={{ '& .super-app-theme--header': { backgroundColor: '#bbdefb', } }} apiRef={apiRef} density="compact"
            rows={dataFilteredA} columns={columns} key={(x) => x.stateId}
            onRowSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const selectedRowData = dataFilteredA.filter((row) =>
                selectedIDs.has(row.stateId.toString())
              );
              setFooRows(selectedRowData);
            }}
            checkboxSelection
            getRowId={(x) => x.stateId}
            pagination={true}
            initialState={{ pagination: { paginationModel: { page: 0, pageSize: 10 }, }, }}
            pageSizeOptions={[10]}
          />

        </CustomTabPanel>

        {/* States Tab (future) */}
        <CustomTabPanel value={value} index={2}>

          <Alert sx={{ mb: 3 }} severity="info">These states are not active in the RCN portal yet.</Alert>

          <div style={{ width: '100%' }}>
            <DataGrid apiRef={apiRef} pagination={true} sx={{ '& .super-app-theme--header': { backgroundColor: '#bbdefb', } }}
              rows={dataFilteredF}
              onRowSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedRowData = dataFilteredF.filter((row) =>
                  selectedIDs.has(row.stateId.toString())
                );
                setFooRows(selectedRowData);
              }}
              key={(x) => x.stateId}
              columns={columns}
              checkboxSelection
              getRowId={(x) => x.stateId}
              initialState={{ pagination: { paginationModel: { page: 0, pageSize: 10 }, }, }}
              pageSizeOptions={[10,25,100]}
            />
          </div>

        </CustomTabPanel>

      </Box>

    </Box>
  )
}