
//-------------------------------------------------------------------------------------------------
// RCN - Town Committee
//-------------------------------------------------------------------------------------------------
import { useState, useEffect, } from "react";
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useForm, useFieldArray } from 'react-hook-form';

// rcn application 
import { TownsClient } from '../web-api-client.ts';
import UserLocation from '../components/common/UserLocation';
import SpecialPermissions from '../components/common/SpecialPermissions';
import MessageMembersButton from '../components/common/MessageMembersButton';
import TownCommitteeNewMember from '../components/TownCommitteeNewMember';
import TownCommitteeMembers from '../components/TownCommitteeMembers';
//import ApplicationStateService from '../services/ApplicationStateService';

// material ui
import { Alert, Button, Box, Typography, TextField, Paper, Stack} from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// component
//-------------------------------------------------------------------------------------------------

const infoFormOptions = {
  addrMeeting: {
    required: "Meeting location is required",
    maxLength: { value: 100, message: "Meeting location must be less than 100 characters" }
  },
  //textLatitude: { required: "Last Name is required" },
  //textLongitude: { required: "Email is required" },
  textDateMeet: { required: "Meeting date/time message" },
  //numChairPhone: { required: "Zipcode is required" },
};

export default function TownCommittee({ applicationUser }) {

  const navigate = useNavigate();
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 0 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  function a11yProps(index) {
    return { id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}`, };
  }

  //#region react state
  const [isEditMode, setIsEditMode] = useState(false);
  const [viewReady, setViewReady] = useState(false);
  const {state} = useLocation();
  const [searchParams ] = useSearchParams();
  const [user,] = useState(applicationUser);
  const [town, setTown] = useState(null);
  const [value, setValue] = useState(0);
  const [isChair, setIsChair] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  //#endregion

  //#region event handlers
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  //#endregion

  //#region react-hook-form requirements
  const [hasErrors, setHasErrors] = useState(false);
  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    //defaultValues: {
    //  links: ['A'],
    //},
  });
  const handleSaveInfo = async (data) => {

    const sites = town.nameSitesRelated.map((obj, index) => {

      if (index === 0) {
        return { ...obj, urlLink: data.nameSiteRelated1 };
      }
      if (index === 1) {
        return { ...obj, urlLink: data.nameSiteRelated2 };
      }

      return obj;
    }); // make updated sites array
    const newTown = { ...town, ...data }; // put form values into town object
    setTown({ ...town, nameSitesRelated: sites }) // update sites

    const api = new TownsClient();
    await api.putTown(newTown.townId, newTown);

    setAlertMessage("Town data was updated.");
    setIsEditMode(false);
  };
  const handleError = (errors) => {
    setHasErrors(true);
  };

  //const { fields, append, remove } = useFieldArray({
  //  name: "links",
  //});
  //#endregion

  useEffect(() => {

    (async () => {

      //todo: something smart
      const townIdS = state != null ? state.townId : null;
      const townIdU = user != null ? user.townId : null;
      const townIdQ = searchParams.get('townId');
      const townId = townIdQ != null ? townIdQ : townIdS != null ? townIdS : user.townId;

      if (townId != null) {

        const apiTown = await (new TownsClient()).getTown(townId, true); // true means get memebers for town
        setTown(apiTown);

        setIsChair(user.personId === apiTown.chairPersonFk);

        setViewReady(true);

      } else {
        navigate("/Home"); //todo: not supposed to be here. must have typed url
      }

    })();

  }, [navigate, searchParams, state, user] ); // just once

  // content
  //-------------------------------------------------------------------------------------------------
  return <Box sx={{ mt: 3 }}>

    <Typography color="red"><h3>Republican Committees Network - Town View (RTC)</h3></Typography>

    {viewReady &&
      <>
        <h1>Town: Massachusetts {'>'} Third Middlesex {'>'} {town.nameTown}</h1>
        <SpecialPermissions page="TOWN" id={town.chairPersonFk} applicationUser={user} />
        <UserLocation appUser={applicationUser} />
        <Typography style={{}} variant="h6" component="h6">
        <strong>Town Committee Chair:</strong> {town.nameChairFirst} {town.nameChairLast}
        <MessageMembersButton buttonText="MESSAGE TOWN CHAIR" sendTo={[town.chairPersonFk]} iconOnly={true} sendToName={`${town.nameChairFirst} ${town.nameChairLast}`}
        />
        <strong>District Chairs:</strong> {town.nameDistrictChairF}, {town.nameDistrictChairM}
        </Typography>
      </>
    }

    <Box sx={{ width: '100%', mt:3 }}>

      <Tabs sx={{ borderBottom: 0, borderColor: 'divider' }}
        value={value} onChange={handleTabChange} aria-label="basic tabs example"
      >
        <Tab label="Town" {...a11yProps(0)} />
        <Tab label="Work-Groups" {...a11yProps(1)} />
        <Tab label="Files/Links" {...a11yProps(2)} />
        <Tab label="Meeting-Notes" {...a11yProps(3)} />
        <Tab label="Info-Requests" {...a11yProps(4)} />
        <Tab label="Members" {...a11yProps(5)} />
        <Tab label="New-Member" {...a11yProps(6)} />
      </Tabs>

      {/*info*/}
      <CustomTabPanel value={value} index={0}>

        {town &&
          <Paper component="form" noValidate autoComplete="off" sx={{ mt: 2, p: 2 }} onSubmit={handleSubmit(handleSaveInfo, handleError)}>

            <Alert severity="warning">Meeting location coordinates (lat/long) should be entered for the map to work correctly. Get them <a href="https://www.latlong.net/" target="_blank" rel="noopener noreferrer">here</a>.</Alert>
            {hasErrors && <Alert sx={{mt:1}} severity="error">Please correct data errors below</Alert>}

            <Stack sx={{ mt: 3, mb: 3 }} direction="row" spacing={1}>

              <TextField fullWidth sx={{ mt: 1, width: 600, }} name="addrMeeting" label="Meeting Location" variant="outlined" type="text"
                defaultValue={town.addrMeeting} inputProps={{ readOnly: !isEditMode }} {...register('addrMeeting', infoFormOptions.addrMeeting)}
                helperText={errors?.addrMeeting && errors.addrMeeting.message}
              />

              <TextField sx={{ mt: 1 }} name="textLatitude" label="Latitude" variant="outlined" type="text"
                defaultValue={town.textLatitude}  {...register('textLatitude', infoFormOptions.textLatitude)}
                helperText={errors?.textLatitude && errors.textLatitude.message}
              />

              <TextField sx={{ mt: 1 }} name="textLongitude" label="Longitude" variant="outlined" type="text"
                defaultValue={town.textLongitude}  {...register('textLongitude', infoFormOptions.textLongitude)}
                helperText={errors?.textLongitude && errors.textLongitude.message}
              />

              <a href="https://www.google.com/maps/@?api=1&map_action=map&center=42.38343000%2C-71.41617000" target="_blank" rel="noopener noreferrer">see on map</a>

            </Stack>

            <TextField sx={{ mt: 2 }} name="textDateMeet" label="Meeting Date" fullWidth variant="outlined" type="text"
              defaultValue={town.textDateMeet}  {...register('textDateMeet', infoFormOptions.textDateMeet)}
              helperText={errors?.textDateMeet && errors.textDateMeet.message}
            />

            <TextField sx={{ mt: 2 }} name="numChairPhone" label="Chair Phone" fullWidth variant="outlined" type="text"
              defaultValue={""}  {...register('numChairPhone', infoFormOptions.numChairPhone)}
              helperText={errors?.numChairPhone && errors.numChairPhone.message}
            />

            {isEditMode &&
              <>
                <TextField sx={{ mt: 2 }} name="textWelcome" label="Welcome Message" fullWidth multiline rows={4}
                  variant="outlined" type="text"
                  defaultValue={town.textWelcome}  {...register('textWelcome', infoFormOptions.textWelcome)}
                  helperText={errors?.textWelcome && errors.textWelcome.message}
                />  
              {true &&
                <>

                <Stack direction="row">
                  <TextField sx={{ mt: 2 }} name="nameSiteRelated1" label="External Site1" fullWidth
                    variant="outlined" type="text"
                    defaultValue={town.nameSitesRelated.length > 0 ? town.nameSitesRelated[0].urlLink : ""}  {...register('nameSiteRelated1', infoFormOptions.nameSiteRelated1)}
                    helperText={errors?.nameSiteRelated1 && errors.nameSiteRelated1.message}
                  />
                  <TextField sx={{ mt: 2 }} name="codeType1" label="Type" fullWidth
                    variant="outlined" type="text"
                    defaultValue={town.nameSitesRelated.length > 0 ? town.nameSitesRelated[0].codeType : ""}  {...register('codeType1', infoFormOptions.codeType1)}
                    helperText={errors?.codeType1 && errors.codeType1.message}
                  />
                </Stack>

                <Stack direction="row">
                  <TextField sx={{ mt: 2 }} name="nameSiteRelated2" label="External Site2" fullWidth
                    variant="outlined" type="text"
                    defaultValue={town.nameSitesRelated.length > 1 ? town.nameSitesRelated[1].urlLink : ""}  {...register('nameSiteRelated2', infoFormOptions.nameSiteRelated1)}
                    helperText={errors?.nameSiteRelated2 && errors.nameSiteRelated2.message}
                  />
                  <TextField sx={{ mt: 2 }} name="codeType2" label="Type" fullWidth
                    variant="outlined" type="text"
                    defaultValue={town.nameSitesRelated.length > 1 ? town.nameSitesRelated[1].codeType : ""}  {...register('codeType2', infoFormOptions.codeType2)}
                    helperText={errors?.codeType2 && errors.codeType2.message}
                  />
                </Stack>

                </>
                }
              </>

              //{fields.map((field, index) => (<Box>{field}</Box>))} not sure this goes here... maybe after the }
            }

            <Stack sx={{ mt: 3 }} direction="row" spacing={1}>

              <Button sx={{ mt: 3, mb: 2 }} disabled={isEditMode} variant="contained"
                onClick={() => setIsEditMode(!isEditMode)}
              >
                EDIT</Button>

              <Button sx={{ mt: 3, mb: 2 }} disabled={!isEditMode} variant="contained"
                onClick={() => {
                  setIsEditMode(false); reset({}); // reset will restore original data
                }}
              >
                CANCEL EDIT</Button>

              <Button type="submit" sx={{ mt: 3, mb: 2 }} disabled={!isEditMode} variant="contained">
                SAVE CHANGES (chair only)</Button>

            </Stack>

          </Paper>
        }

      </CustomTabPanel>

      {/*work groups*/}
      <CustomTabPanel value={value} index={1}>
        <Alert sx={{ mt: 2, p: 2 }}>under construction -- special projects and work groups</Alert>
      </CustomTabPanel>

      {/*files/links*/}
      <CustomTabPanel value={value} index={2}>
        <Alert sx={{ mt: 2, p: 2 }}>under construction -- saved files and site links</Alert>
      </CustomTabPanel>

      {/*Meeting Notes*/}
      <CustomTabPanel value={value} index={3}>
        <Alert sx={{ mt: 2, p: 2 }}>under construction -- </Alert>
      </CustomTabPanel>

      {/*Info Requests*/}
      <CustomTabPanel value={value} index={4}>
        <Alert sx={{ mt: 2, p: 2 }}>under construction - see a list of people that requested RTC information</Alert>
      </CustomTabPanel>

      {/*members*/}
      <CustomTabPanel value={value} index={5}>
        <TownCommitteeMembers user={user}></TownCommitteeMembers>
      </CustomTabPanel>

      {/*new member*/}
      <CustomTabPanel value={value} index={6}>
        <TownCommitteeNewMember user={user}></TownCommitteeNewMember>
      </CustomTabPanel>

    </Box>

  </Box>
}