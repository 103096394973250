
//-------------------------------------------------------------------------------------------------
// RCN - District Onboarding Dialog
//-------------------------------------------------------------------------------------------------

import { useState, useEffect } from "react";
import { useForm, } from 'react-hook-form';

//material ui
import { Alert, Button, Box, Typography, Stack, Paper } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { AppBar, Toolbar } from '@mui/material';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

// material icons
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';

// rcn application
import ApplicationStateService from '../services/ApplicationStateService';
import UserLocation from '../components/common/UserLocation';
import { PeopleClient, UserClient, DistrictsClient, MessageClient } from '../web-api-client.ts';

//-------------------------------------------------------------------------------------------------
// component
//-------------------------------------------------------------------------------------------------
export default function StateDistrictOnboarding({ isOpen, setOpen, name, id, refreshDistricts }) {

  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const handleRegistration = async (data) => {

    const resultPut = await (new DistrictsClient().putDistrict(id, {
      personIdChairMale: district.personIdChairMale,
      personIdChairFemale: district.personIdChairFemale,
      codeStatus: activateDistrict ? 'ACTIVE' : 'PENDING'
    }));

    refreshDistricts(); // let called know what we did

    //await (new MessageClient().postMessage(
    //  {
    //    messageId: 123,
    //    textMessageSubject: data.msgSubject,
    //    textMessageBody: data.msgBody,
    //    textMessageBodyX: `Name: ${data.msgName} Email: ${data.msgEmail} Phone: ${data.msgPhone}`,
    //    personIdSendFrom: props.town.chairPersonFk,
    //    personIdsSendTo: [props.town.chairPersonFk]
    //  }));

    setHasErrors(false);
    setWasSent(true);
    setAlertMessage(`Request was sent to the district chairs... the chair can add district towns when ready.`);
  };
  const handleError = (errors) => {
    setHasErrors(true);
  };
  const registerOptions = {

    nameChairManFirst: { required: "First name is required" },
    nameChairManLast: { required: "Last Name is required" },
    emailChairMan: { required: "Email is required" },
    //nameChairManPhone: { required: "Phone is required" },

    nameChairWomanFirst: { required: "First name is required" },
    nameChairWomanLast: { required: "Last Name is required" },
    emailChairWoman: { required: "Email is required" },
    //nameChairWomanPhone: { required: "Phone is required" },
  };

  //#region react/component state
  const [activateDistrict, setActivateDistrict] = useState(true);
  const [viewReady, setViewReady] = useState(false);
  const [district, setDistrict] = useState(null);
  const [wasSent, setWasSent] = useState(false);
  const [, setHasErrors] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  //const [alertStatus,] = useState("info");
  //#endregion

  useEffect(() => {

    if (id) { setAlertMessage(`This district will be activated and chair(s) notified.`); }
    else { setAlertMessage(`You are adding a new district... it can be activated now or later when needed.`); }

    (async (id) => {

      if (id) {
        const district = await (new DistrictsClient()).getDistrict(id, false);
        setDistrict(district);
      }
      else {
        setDistrict({ districtId: null, nameDistrict: 'new district creation' }); //todo: support new district add and activate
      }

      setViewReady(true);

    })(id /*iife*/);

  }, [id]);

  //#region event handlers
  const handleClose = () => {
    setOpen(false);
    setWasSent(false);
  };
  //async function createUser(id, psw, addr, zipcode, name) {

  //  //var district = formData;

  //  const pid = await (new PeopleClient()).createPerson(
  //    {
  //      nameFirst: "fname", nameLast: "lname", numZipCode: 12345, addrRegistered: "39 Wolbach Rd", townId: 1,
  //    });

  //  const api = await new UserClient().user_CreateUser(
  //    {
  //      personId: pid, loginId: "hellodave", loginPassword: "rtc@2024Login", loginEmail: "hellodave@foo.com",
  //    });

  //  return;
  //}
  //#endregion

  //-------------------------------------------------------------------------------------------------
  // content
  //-------------------------------------------------------------------------------------------------
  return (
    <>
      <Dialog maxWidth={'lg'} open={isOpen} onClose={() => setOpen(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">

        <AppBar sx={{ position: 'relative' }}>

          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {"District Onboarding"} - {viewReady && district.nameDistrict}
            </Typography>
            {/*<Button autoFocus disabled={wasSent} color="inherit" onClick={handleSave}>SAVE</Button>*/}
          </Toolbar>

        </AppBar>

        <DialogTitle id="alert-dialog-title">Add/change required data and click SAVE</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">

            <Alert>{alertMessage}</Alert>

            <Paper component="form" noValidate sx={{ mt: 3, p: 1 }} onSubmit={handleSubmit(handleRegistration, handleError)}>

              {viewReady &&
                <>
                  <Box sx={{ mt: 1 }}>District Chairman</Box>
                  <Stack sx={{ mt: 2 }} direction="row" spacing={1}>
                    <TextField name="nameChairManFirst" label="First Name" fullWidth variant="outlined"
                      value={district.nameChairManFirst} inputProps={{ readOnly: wasSent }} {...register('nameChairManFirst', registerOptions.nameChairManFirst)} type="text"
                      helperText={errors?.nameChairManFirst && errors.nameChairManFirst.message}
                    />
                    <TextField name="nameChairManLast" label="Last Name" fullWidth variant="outlined"
                      value={district.nameChairManLast} inputProps={{ readOnly: wasSent }} {...register('nameChairManLast', registerOptions.nameChairManLast)} type="text"
                      helperText={errors?.nameChairManLast && errors.nameChairManLast.message}
                    />
                    <TextField name="emailChairMan" label="Email" fullWidth variant="outlined"
                      value={district.emailChairMan} inputProps={{ readOnly: wasSent }} {...register('emailChairMan', registerOptions.emailChairMan)} type="email"
                      helperText={errors?.emailChairMan && errors.emailChairMan.message}
                    />
                    <TextField name="nameChairManPhone" label="Phone" fullWidth variant="outlined"
                      inputProps={{ readOnly: wasSent }} {...register('nameChairManPhone', registerOptions.nameChairManPhone)} type="phone"
                      helperText={errors?.nameChairManPhone && errors.nameChairManPhone.message}
                    />
                  </Stack>

                  <Box sx={{ mt: 3 }}>District Chairwoman</Box>
                  <Stack sx={{ mt: 2 }} direction="row" spacing={1}>
                    <TextField name="nameChairWomanFirst" label="First Name" fullWidth variant="outlined"
                      value={district.nameChairWomanFirst} inputProps={{ readOnly: wasSent }} {...register('nameChairWomanFirst', registerOptions.nameChairWomanFirst)} type="text"
                      helperText={errors?.nameChairWomanFirst && errors.nameChairWomanFirst.message}
                    />
                    <TextField name="nameChairWomanLast" label="Last Name" fullWidth variant="outlined"
                      value={district.nameChairWomanLast} inputProps={{ readOnly: wasSent }} {...register('nameChairWomanLast', registerOptions.nameChairWomanLast)} type="text"
                      helperText={errors?.nameChairWomanLast && errors.nameChairWomanLast.message}
                    />
                    <TextField name="emailChairWoman" label="Email" fullWidth variant="outlined"
                      value={district.emailChairWoman} inputProps={{ readOnly: wasSent }} {...register('emailChairWoman', registerOptions.emailChairWoman)} type="email"
                      helperText={errors?.emailChairWoman && errors.emailChairWoman.message}
                    />
                    <TextField name="nameChairWomanPhone" label="Phone" fullWidth variant="outlined"
                      inputProps={{ readOnly: wasSent }} {...register('nameChairWomanPhone', registerOptions.nameChairWomanPhone)} type="phone"
                      helperText={errors?.nameChairWomanPhone && errors.nameChairWomanPhone.message}
                    />
                  </Stack>

                  <FormControlLabel sx={{ mt: 3 }} disabled={district.districtId} control={<Checkbox defaultChecked />}
                    value={district.districtId ? false : true}
                    label={<Typography>Activate this district</Typography>}
                    onClick={() => { setActivateDistrict(!activateDistrict) }} />

                  <Box sx={{ mt: 3, }}>
                    <h4>Saving Will Allow The Following</h4>
                    <List sx={{ pl: 4, listStyleType: 'disc' }} dense={true} >
                      <ListItem sx={{ display: 'list-item' }}>The district will be placed in the ACTIVE state</ListItem>
                      <ListItem sx={{ display: 'list-item' }} disabled={!activateDistrict}>Each chair will receive an email notification</ListItem>
                      <ListItem sx={{ display: 'list-item' }} disabled={!activateDistrict}>The district chair will now be able to onboard towns</ListItem>
                    </List>
                  </Box>

                  <Button sx={{ mt: 3, mb: 2 }} type="submit" disabled={wasSent} fullWidth variant="contained">
                    SAVE CHANGES
                  </Button>
                </>
              }

            </Paper>

          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpen(false)}>CANCEL</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}