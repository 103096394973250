
//
// RCN - Main Navigation Menu (mobile)
//
import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
// mui
import Link from '@mui/material/Link';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
//import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AdbIcon from '@mui/icons-material/Adb';
import GroupsIcon from '@mui/icons-material/Groups';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import AccountCircle from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import MessageIcon from '@mui/icons-material/Message';

//const settingsSave = ['Profile', 'Account', 'Dashboard', 'Logout'];
const isMobile = false; // window.screen.width < 600;

//#region searchbar
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));
const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));
//#endregion

export default function NavMenuMobile(props) {

  //#region react state
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [menuPages, setMenuPages] = React.useState([
    //{ name: 'Town', url: '/TownWelcome', color: 'inherit' },
    //{ name: 'District', url: '/DistrictHome', color: 'inherit' },
    //{ name: 'State', url: '/StateHome', color: 'inherit' },
    //{ name: 'NATIONAL', url: '/USAHome', color: 'inherit' },
  ]);
  const [activePage, setActivePage] = React.useState("Town");
  const [menuSettings, setMenuSettings] = React.useState(['Lxxxogout']);
  //#endregion

  //#region event handlers
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {

    setAnchorElUser(event.currentTarget);

    if (props.applicationUser) {
      setMenuSettings(["Logout"]);
    }
    else {
      setMenuSettings(["Login"]);
    }
  };
  const handleCloseNavMenu = () => {

    const newState = menuPages.map(obj => {
      if (obj.name === activePage) {
        return { ...obj, color: 'red' };
      }
      return obj; // otherwise return the object as is
    });

    setMenuPages(newState);
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = (menuItem) => {

    setAnchorElUser(null);

  };
  const handleClickUserMenu = (event) => {

    event.preventDefault();

    if (props.applicationUser)
    {
      props.logout();
    }
    else
    {
      navigate("/LogInOut");
    }

    setAnchorElUser(null);

  };
  //#endregion

  return (
    <AppBar backgroundColor="#1976d2" position="static">
      <Container backgroundColor="#1976d2" maxWidth="xl">
        <Toolbar disableGutters backgroundColor="#1976d2">

          <>

            <Typography variant="h6" noWrap component="a" href="/"
              sx={{
                mr: 2, ml:0,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace', fontWeight: 700, color: 'inherit', textDecoration: 'none',
              }}
            >
              RCN
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton size="large" onClick={() => { /* nothing on mobile yet */ } } color="inherit">
                <MenuIcon />
              </IconButton>
              <Menu id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'left', }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{ display: { xs: 'block', md: 'none' } }}
              >
                {menuPages.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography sx={{ textAlign: 'center' }}>{page.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Typography variant="h5" noWrap component="a" href="/"
              sx={{
                mr: 2, display: { xs: 'flex', md: 'none' }, flexGrow: 1, fontFamily: 'monospace', fontWeight: 700, color: 'inherit', textDecoration: 'none',
              }}
            >
              RCNU
            </Typography>

          </>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {[/* nothing on mobile yet */].map((page) => (
              <Button key={page.name}
                component={Link} href={props.applicationUser ? page.url : "/"}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: page.color, display: 'block' }}
              >
                {page.name}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>

            <Tooltip title="Member Settings" placement="left-start">
              <IconButton onClick={() => { /* nothing for mobile yet*/ } } sx={{ p: 0 }}>
                <AccountCircle sx={{ fontSize: 40 }} />
              </IconButton>
            </Tooltip>

            <Menu id="menu-appbar" keepMounted sx={{ mt: '45px' }}
              anchorEl={anchorElUser}
              anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
              transformOrigin={{ vertical: 'top', horizontal: 'right', }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {[/* nothing on mobile yet */].map((setting) => (
                <MenuItem key={setting} component={Link} onClick={() => { /* nothing for mobile yet */ } }>
                  <Typography sx={{ textAlign: 'center' }}>{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>

          </Box>

        </Toolbar>
      </Container>
    </AppBar>
  );
}
