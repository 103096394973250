
//-------------------------------------------------------------------
// TC - Entry Point for Town-Committee Application
//-------------------------------------------------------------------
import axios from "axios";
import { React, useState, useEffect, createContext } from "react";
import { Route, Routes } from 'react-router-dom';

// material ui
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';

// rcn application
//import MESSAGES from '../src/constants.js';
import AppRoutes from './AppRoutes';
import Layout from './components/Layout';
//import LayoutFooter3 from './components/LayoutFooter3';
//import LayoutFooter2 from './components/LayoutFooter2';
import LayoutFooter from './components/LayoutFooter';
import './custom.css';

import { PeopleClient, AppPropertiesClient } from './web-api-client.ts'; // server end-points
import ApplicationStateService from './services/ApplicationStateService';
import LocalStorageService from './services/LocalStorageService';

import Home from './components/Home';
import HomeMobile from './components/HomeMobile';
import HomeDashboard from './components/HomeDashboard';
import USAHome from './components/USAHome';
import StateHome from './components/StateHome';
import StateAdmin from './components/StateAdmin';
import DistrictAdmin from './components/DistrictAdmin';
import DistrictHome from './components/DistrictHome';
import TownAdmin from './components/TownAdmin';
import TownWelcome from './components/TownWelcome';
import TownCommittee from './components/TownCommittee';
import PeopleView from './components/PeopleView';
import MessageCenter from './components/MessageCenter';
import EventCalendar from './components/EventCalendar';
import MapTest from './components/MapTest';
import LogInOut from './components/LogInOut';
import HomeCheckItOut from './components/HomeCheckItOut';
import SiteInfo from './components/SiteInfo';

export default function App() {

  const UserContext = createContext();
  const isMobile = window.screen.width < 600;

  //#region component state
  const [appStateService,] = useState(ApplicationStateService());
  const [LSS,] = useState(LocalStorageService("members"));
  const [identityUser, setIdentityUser] = useState(null);
  const [applicationUser, setApplictionUser] = useState(null);
  const [applicationPerson, setApplictionPerson] = useState(null);
  const [loginRequest, setLoginRequest] = useState(null);
  const [appReady, setAppReady] = useState(false);
  const [demoLocked, setDemoLocked] = useState(false);

  //#endregion component state

  useEffect(() => {

    if (!LSS.getItem()) {
      Promise.resolve((new PeopleClient().getAllPeople()).then((value) => {
        LSS.setItem(value);
      } ));
    }

    (async () => {

      const appProperties = await (new AppPropertiesClient()).getAppProperties();
      setDemoLocked(appProperties.demoLocked);

      const ui = await appStateService.identity();

      if (ui)
      {
        setIdentityUser(ui);
        setApplictionPerson(ui.person);
        setApplictionUser(ui);
        setApplictionUser((current) => current = { ...current, nameTown: ui.person.nameTown }); // add name-town to user
        setLoginRequest("OUT");
      }
      else //user not logged in
      {
        setIdentityUser(null);
        setApplictionPerson(null);
        setApplictionUser(null);
        setLoginRequest("IN");
      }

      setAppReady(true);

    })();

  }, [] ); // just once

  //https://dev.to/salehmubashar/conditional-routing-with-react-router-v6-229g

  return (

    <>
      {!isMobile &&
        <UserContext.Provider value={appStateService}>

          <Layout applicationUser={applicationUser} logout={logout} >
            {appReady &&

              <Routes>
                {/* conditional routes */}
                <Route exact path="/town" element={applicationUser
                  ? <TownCommittee applicationUser={applicationPerson} appStateService={appStateService} />
                  : <TownWelcome applicationUser={applicationPerson} appStateService={appStateService} />}
                />

                <Route exact path="/home" element={applicationUser
                  ? <HomeDashboard applicationUser={applicationPerson} identityUser={identityUser} />
                  : <Home applicationUser={applicationPerson} identityUser={identityUser} demoLocked={demoLocked} />}
                />

                <Route exact path="/" element={applicationUser
                  ? <HomeDashboard applicationUser={applicationPerson} identityUser={identityUser} />
                  : <Home applicationUser={applicationPerson} identityUser={identityUser} demoLocked={demoLocked} />}
                />

                {/* all other routes */}
                <Route exact path="/HomeMobile" element={<HomeMobile applicationUser={applicationPerson} identityUser={identityUser} />} />
                <Route exact path="/USAHome" element={<USAHome applicationUser={applicationPerson} identityUser={identityUser} appStateService={appStateService} />} />

                <Route exact path="/StateHome" element={<StateHome applicationUser={applicationPerson} identityUser={identityUser} appStateService={appStateService} />} />
                <Route exact path="/StateAdmin" element={<StateAdmin applicationUser={applicationPerson} />} />

                <Route exact path="/DistrictHome" element={<DistrictHome applicationUser={applicationPerson} appStateService={appStateService} />} />
                <Route exact path="/DistrictAdmin" element={<DistrictAdmin applicationUser={applicationPerson} />} />

                <Route exact path="/TownWelcome" element={<TownWelcome applicationUser={applicationPerson} />} appStateService={appStateService} />
                <Route exact path="/TownCommittee" element={<TownCommittee applicationUser={applicationPerson} />} />
                <Route exact path="/TownAdmin" element={<TownAdmin applicationUser={applicationPerson} />} />

                <Route exact path="/PeopleView" element={<PeopleView applicationUser={applicationPerson} />} appStateService={appStateService} />
                <Route exact path="/MessageCenter" element={<MessageCenter applicationUser={applicationPerson} />} appStateService={appStateService} />
                <Route exact path="/EventCalendar" element={<EventCalendar applicationUser={applicationPerson} />} appStateService={appStateService} />
                <Route exact path="/MapTest" element={<MapTest applicationUser={applicationPerson} />} appStateService={appStateService} />
                <Route exact path="/HomeCheckItOut" element={<HomeCheckItOut />} appStateService={appStateService} />
                <Route exact path="/SiteInfo" element={<SiteInfo />} appStateService={appStateService} />

                <Route exact path="/LogInOut" element={
                  <LogInOut applicationUser={applicationUser}
                    loginRequest={loginRequest}
                    loginHandler={loginRequest === null ? null : loginRequest === "IN" ? login : login}
                  />
                } />

                {AppRoutes.map((route, index) => {
                  const { element, ...rest } = route;
                  return <Route key={index} {...rest} element={element} />; //todo: not exactly sure what this does
                })}
              </Routes>
            }
          </Layout>
          <LayoutFooter></LayoutFooter>

        </UserContext.Provider> 
      }

      {isMobile &&
        <>
          <CssBaseline />
          <Container maxWidth="sm">
          <Box sx={{ bgcolor: '#cfe8fc', height: '100vh', p:10 }}>
            <Stack sx={{ alignItems: 'center', justifyContent: 'center' }}>
              <h1>RCN United</h1>
              <h4>mobile</h4>
              <Box sx={{ mt: 3, textAlign: 'center' }}>
                <p>The Republican Committees Network mobile experience is a work in progress. Please visit RCNUnited.com on your notebook or desktop computer.</p>
              </Box>
            </Stack>
          </Box>
          </Container>
        </>
      }
    </> 
    
  );

  //-------------------------------------------------------------------
  // view helpers
  //-------------------------------------------------------------------
  async function login(id, psw) {

    setApplictionUser(null); let retValue = false;

    await axios.post("api/user", JSON.stringify({ loginId: id, loginPassword: psw }), { headers: { "Content-Type": "application/json" }, withCredentials: true, })
    .then((response) => {

      if (response.data) {
        setApplictionUser(response.data);
        setIdentityUser(response.data);
        setApplictionPerson(response.data.person);
        retValue = true;
      }

    })
    .catch((error) => { retValue = false; });

    return retValue;
  }
  function logout() {

    axios.put("api/user").then((response) => {
      setApplictionUser(null);
      setIdentityUser(null);

      return true;
    });
  }
}
