
// this service will provide all application state data objects

import axios from "axios";
import { TownsClient, DistrictsClient, StateClient } from '../web-api-client.ts';

export default function ApplicationStateService() {

  let userIdentity = null;

  return {

    identity: async function () {

      let returnValue = null;

      await axios.get("api/user/") //get logged on user from prior session
        .then((response) => {

          if (response.data) {
            returnValue = response.data;

            userIdentity = returnValue;
            userIdentity.state = {};
            userIdentity.district = {};
          }

        })
        .catch((error) => {
          console.error(error);
        });

      return returnValue;

    },

    //
    person: async function () {
      return userIdentity.person;
    },

    //
    town: async function () {
      return userIdentity.town;
    },
    towns: async function (districtId = null) {

      let returnValue = null;

      if (districtId == null) {
        returnValue = await (new TownsClient()).getAllTowns();
      }
      else
      {
        returnValue = await (new TownsClient()).getAllTownsByDistrict(districtId);
      }


      return returnValue;
    },

    //
    district: async function (districtId) {

      if (districtId == null) return userIdentity.district; // logged on user

      const returnValue = await (new DistrictsClient().getDistrict(districtId));
      return returnValue;
    },
    districts: async function (stateId = null) {

      const returnValue = await (new DistrictsClient()).getAllDistricts(stateId);

      return returnValue;
    },

    //
    state: async function (stateId) {

      if (stateId == null) return userIdentity.state; // logged on use state

      const returnValue = await (new StateClient().getState(stateId));
      return returnValue;
    },
    states: async function () {

      const returnValue = await (new StateClient().getStates());

      return returnValue;

      //return [
      //  { stateId: "MA", nameState: "Massachusetts", nameOwner: "David Anderson", nameAdmin: "David Anderson", numCommCount: "75/350", numMemberCount: "700" },
      //  { stateId: "TX", nameState: "Texas", nameOwner: "David Anderson", nameAdmin: "David Anderson", numCommCount: "99/450", numMemberCount: "850" }
      //];
    }

  }
};