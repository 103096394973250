
//
// RCN - Main Navigation Menu 
//
import * as React from 'react';
//import { styled, alpha } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import LocalStorageService from '../services/LocalStorageService';

// mui
import Grid from '@mui/material/Grid';
import Popover from '@mui/material/Popover';
import Link from '@mui/material/Link';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Badge from '@mui/material/Badge';
//import InputAdornment from '@mui/material/InputAdornment';
//import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AdbIcon from '@mui/icons-material/Adb';
import GroupsIcon from '@mui/icons-material/Groups';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import AccountCircle from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';

export default function NavMenu({ applicationUser, logout }) {

  //#region react state
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [menuPages, setMenuPages] = React.useState([
    { name: 'Town', url: '/Town', color: 'inherit' },
    { name: 'District', url: '/DistrictHome', color: 'inherit' },
    { name: 'State', url: '/StateHome', color: 'inherit' },
    { name: 'NATIONAL', url: '/UsaHome', color: 'inherit' },
  ]);
  const [activePage, setActivePage] = React.useState("Town");
  const [menuSettings, setMenuSettings] = React.useState(['Lxxxogout']);
  const [searchValue, setSearchValue] = React.useState("");
  const [searchMembers, setSearchMembers] = React.useState([]);
  const [selectedSearchMember, setSelectedSearchMember] = React.useState(null);
  //#endregion

  //#region event handlers
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {

    setAnchorElUser(event.currentTarget);

    if (applicationUser) {
      setMenuSettings(["Logout"]);
    }
    else {
      setMenuSettings(["Login", "Member Profile"]);
    }
  };
  const handleCloseNavMenu = () => {

    const newState = menuPages.map(obj => {
      if (obj.name === activePage) {
        return { ...obj, color: 'red' };
      }
      return obj; // otherwise return the object as is
    });

    setMenuPages(newState);
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = (menuItem) => {

    setAnchorElUser(null);

  };
  const handleClickUserMenu = (event) => {

    event.preventDefault();

    if (applicationUser)
    {
      logout();
    }
    else
    {
      navigate("/LogInOut");
    }

    setAnchorElUser(null);

  };
  //#endregion

  //#region member search
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [LSS,] = React.useState(LocalStorageService("members"));
  const allMembers = LSS.getItem();
  const handleClose = () => {
    setAnchorEl(null);
    setSearchValue("");
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleLoadMembers = (member) => {
    const result = allMembers.filter(element => element.namePersonLast.toUpperCase().startsWith(member.toUpperCase()));
    setSearchMembers(result);
  };
  const handleSelectedMember = (event, member) => {

    if (member) {

      setSelectedSearchMember(member);

      setSearchMembers([]);
      setAnchorEl(event.currentTarget);
    }
  };
  //#endregion

  return (
    <AppBar backgroundcolor="#1976d2" position="static">

      <Container backgroundcolor="#1976d2" maxWidth="xl">

        <Toolbar disableGutters backgroundcolor="#1976d2">
          {false && <GroupsIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 2 }} fontSize="large" /> }
          <Typography variant="h6" noWrap component="a" href="/" sx={{ mr:2, display: { xs:'none', md:'flex' }, fontFamily:'monospace', fontWeight:700, color:'inherit', textDecoration:'none', }}
          >
            HOME/RCNU
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu id="menu-appbar" anchorEl={anchorElNav} anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }} keepMounted
              transformOrigin={{ vertical: 'top', horizontal: 'left', }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: 'block', md: 'none' } }}
            >
              {menuPages.map((page, x) => (
                <MenuItem key={x} onClick={handleCloseNavMenu}>
                  <Typography sx={{ textAlign: 'center' }}>{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <AdbIcon sx={{ display: { xs:'flex', md:'none' }, mr:1 }} />
          <Typography variant="h5" noWrap component="a" href="/" sx={{ mr: 2, display: { xs: 'flex', md: 'none' }, flexGrow: 1, fontFamily: 'monospace', fontWeight: 700, letterSpacing: '.3rem', color: 'inherit', textDecoration: 'none', }}
          >
            RCN
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {menuPages.map((page, x) => (
              <Button sx={{ my: 2, color: page.color, display: 'block' }} key={x} component={Link}
                disabled={!applicationUser}
                href={applicationUser ? page.url : "/"}
                onClick={handleCloseNavMenu}>
                {page.name}
              </Button>
            ))}
          </Box>

          {applicationUser &&
            <Autocomplete sx={{ width: 300 }} size="small" disablePortal id="combo-box-demo"
              onInputChange={ // reload members array
                (event, member) => {
                  if (member) {
                    handleLoadMembers(member);
                    setSearchValue(member);
                  }
                }
              }
              onChange={(event, member) => { handleSelectedMember(event, member) }}
              noOptionsText={'Begin Typing Member Name'}
              value={searchValue}
              options={searchMembers}
              getOptionLabel={(option) => option.namePersonLast || searchValue}
              isOptionEqualToValue={(option, value) => true}
              forcePopupIcon={true}
              ListboxProps={{ style: { maxHeight: 150 } }}
              renderInput={(params) =>
                <TextField type="search" placeholder="Search Members" {...params} />
              }
            />
          }
          {selectedSearchMember &&
            <Popover sx={{ mt: 15, display: "flex", justifyContent: "center", alignItems: "top", }}
              id={id} open={open} anchorReference={"none"}
              onClose={handleClose}
            >
              <Box sx={{ p: 3, width: 500, height: 250, backgroundcolor: "#dedede" }}>
                <h3>See {selectedSearchMember.namePersonFirst} {selectedSearchMember.namePersonLast}'s</h3>
                <Grid sx={{ mt: 1 }} container spacing={2}>
                  <Grid item xs={12} fullwidth>
                    <Button sx={{ width: '100%' }} variant="outlined"
                      component={Link} href={"/towncommittee?townId=" + selectedSearchMember.townId}
                    >
                      {"Town..."}{selectedSearchMember.nameTown}
                    </Button>
                  </Grid>
                  <Grid item xs={12} fullwidth>
                    <Button sx={{ width: '100%' }} variant="outlined"
                      component={Link} href={"/districthome?districtId=" + selectedSearchMember.districtId}
                    >
                      {"District..."}{selectedSearchMember.nameDistrict}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Popover>
          }  

          <Box sx={{ ml: 1, flexGrow: 0 }}>
            {applicationUser &&
              <Tooltip title="Message Center" placement="left-start">
                <IconButton component={Link} href={applicationUser ? "/MessageCenter" : "/"} sx={{ p: 0 }}>
                  <Badge badgeContent={3} color="secondary">
                    <EmailIcon sx={{ fontSize: 40 }} />
                  </Badge>
                </IconButton>
              </Tooltip>              
            }
          </Box>

          <Box sx={{ ml: 4, mr: 2 }} >
            {applicationUser != null ? `${applicationUser.userName}/${applicationUser.person.nameTown}` : "Login"}
          </Box>

          <Box sx={{ flexGrow: 0 }}>

            <Tooltip title="Member Settings" placement="left-start">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <AccountCircle sx={{ fontSize: 40 }} />
              </IconButton>
            </Tooltip>

            <Menu id="menu-appbar" keepMounted sx={{ mt: '45px' }}
              anchorEl={anchorElUser}
              anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
              transformOrigin={{ vertical: 'top', horizontal: 'right', }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {menuSettings.map((setting, x) => (
                <MenuItem key={x} disabled={x === 1} component={Link} onClick={handleClickUserMenu}>
                  <Typography sx={{ textAlign: 'center' }}>{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>

          </Box>

        </Toolbar>

      </Container>
    </AppBar>
  );
}
