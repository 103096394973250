
//-------------------------------------------------------------------------------------------------
// RCN - Event View Dialog
//-------------------------------------------------------------------------------------------------

import { useState, useEffect, useRef } from "react";
import { useForm, } from 'react-hook-form';
//material ui
import { Alert, Button, Box, Typography, Paper } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { AppBar, Toolbar } from '@mui/material';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
// material icons
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
// rcn application
import ApplicationStateService from '../../services/ApplicationStateService';
import { MessageClient } from '../../../src/web-api-client.ts';

//-------------------------------------------------------------------------------------------------
// component
//-------------------------------------------------------------------------------------------------
export default function EventViewerDialog(props) {

  //#region react/component state
  const { register, handleSubmit, formState: { errors } } = useForm();
  const handleRegistration = async (data) => {

    //await (new MessageClient().postMessage(
    //  {
    //    messageId: 123,
    //    textMessageSubject: data.msgSubject,
    //    textMessageBody: data.msgBody,
    //    textMessageBodyX: `Name: ${data.msgName} Email: ${data.msgEmail} Phone: ${data.msgPhone}`,
    //    personIdSendFrom: props.town.chairPersonFk,
    //    personIdsSendTo: [props.town.chairPersonFk]
    //  }));

    //setHasErrors(false);
    //setWasSent(true);
    //setAlertMessage(`The information request was sent to the RTC chair... expect a call or email soon.`);

 };
  const handleError = (errors) => {
    setHasErrors(true);
  };
  const registerOptions = {
    msgBody: {
      required: "Body is required",
      minLength: {
        value: 10,
        message: "Body must have at least 10 characters"
      }
    },
    msgName: { required: "Name is required" },
    msgEmail: { required: "Email is required" },
    msgPhone: {
      required: "Phone is required",
      minLength: {
        value: 10,
        message: "Phone must have at least 10 characters"
      }
    }
  };

  const [open,] = useState(props.open);
  const [hasErrors, setHasErrors] = useState(false);
  const [subjectError, ] = useState(false);
  const [wasSent, setWasSent] = useState(false);
  const [formData, setFormData] = useState({ msgSubject: 'Information Request - Sudbury', msgEmail:'', msgBody:'', msgPhone:'', msgName:''});
  const [viewReady, setViewReady] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertStatus, ] = useState("info");
  //#endregion

  const myRef = useRef(null);

  useEffect(() => {

    //if (props.town.chairPersonFk === null) {
    //  setAlertMessage(`The district committee chair from ${props.town.nameDistrict} will get back as soon as possible.`);
    //} else {
    //  setAlertMessage(`The ${props.town.nameTown} RTC chair, ${props.town.nameChairFull}, will get back as soon as possible.`);
    //}

    (async () => {

      //setViewReady(true);

    })(/*iife*/);

  }, []);

  //#region event handlers
  //#endregion

  //-------------------------------------------------------------------------------------------------
  // component content
  //-------------------------------------------------------------------------------------------------
  return (
    <>
      <Dialog fullWidth maxWidth="lg" open={open} onClose={props.handleMessageClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">

        <AppBar sx={{ position: 'relative' }}>

          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={props.handleMessageClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Event View
            </Typography>
          </Toolbar>

        </AppBar>

        <DialogTitle id="alert-dialog-title"></DialogTitle>

        <DialogContent>

          <DialogContentText id="alert-dialog-description">

            <Alert severity={alertStatus}>{alertMessage}</Alert>

            {hasErrors && <Alert severity="error">Please correct/complete data and try SEND again</Alert>}

            <Paper ref={myRef} component="form" noValidate autoComplete="off" sx={{ mt: 3, p: 1 }} onSubmit={handleSubmit(handleRegistration, handleError)}>

              <Button disabled={wasSent} type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                SEND
              </Button>

            </Paper>

          </DialogContentText>

        </DialogContent>

        <DialogActions>
          <Button onClick={props.handleMessageClose}>CANCEL</Button>
        </DialogActions>

      </Dialog>
    </>
  );
}