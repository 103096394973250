
//-------------------------------------------------------------------------------------------------
// RCN - Town Committee
//-------------------------------------------------------------------------------------------------
import { useState, useEffect, useRef } from "react";
import { styled } from '@mui/material/styles';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useForm, } from 'react-hook-form';

// rcn application 
import { TownsClient, UserClient, PeopleClient } from '../web-api-client.ts';
import ApplicationStateService from '../services/ApplicationStateService';
import UserLocation from '../components/common/UserLocation';
import SpecialPermissions from '../components/common/SpecialPermissions';
import MessageMembersButton from '../components/common/MessageMembersButton';

// material ui
import { Alert, Button, Box, Typography, TextField, Paper, Grid, } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import DeleteIcon from '@mui/icons-material/Delete';
//import EditIcon from '@mui/icons-material/Edit';
//import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';

// component
//-------------------------------------------------------------------------------------------------
export default function TownCommitteeNewMember({user}) {

  //#region form state
  const [hasErrors, setHasErrors] = useState(false);
  const myRef = useRef(null);
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const handleRegistration = async (data) => {

    data.townId = user.townId;
    const id = await (new PeopleClient()).createPerson(data);

    setAlertMessage("new committee member was added");

    const api = new UserClient();
    await api.user_CreateUser({ PersonId: id, LoginId: data.nameFirst, LoginPassword:'rtc@2024Login', LoginEmail:data.email, });

    reset({});
    //setHasErrors(false);
    //setWasSent(true);
    //setAlertMessage(`The information request was sent to the RTC chair... expect a call or email soon.`);
  };
  const handleError = (errors) => {
    setHasErrors(true);
  };
  const handleInputChange = (event) => {

    const { name, value } = event.target;
    setFormData((prevProps) => ({
      ...prevProps,
      [name]: value
    }));

  };
  const registerOptions = {
    nameFirst: { required: "First Name is required" },
    nameLast: { required: "Last Name is required" },
    email: { required: "Email is required" },
    addrRegistered: { required: "Address is required" },
    numZipCode: { required: "Zipcode is required" },
  };
  //#endregion

  //#region react state
  const [wasSent, setWasSent] = useState(false);
  const [viewReady, setViewReady] = useState(false);
  const {state} = useLocation();
  const [searchParams ] = useSearchParams();
  //const [user,] = useState(props.applicationUser);
  const [town, setTown] = useState(null);
  const [members, setMembers] = useState([]);
  const [value, setValue] = useState(0);
  const [isChair, setIsChair] = useState(false);
  const [formData, setFormData] = useState({});
  const [alertMessage, setAlertMessage] = useState("New committee-member will get an email with login instructions.");
  //#endregion

  useEffect(() => {

    (async () => {

      const townIdS = state != null ? state.townId : null;
      const townIdU = user != null ? user.townId : null;
      const townIdQ = searchParams.get('townId');

      const townId = townIdQ != null ? townIdQ : townIdS != null ? townIdS : user.townId;

      if (townId != null) {

        //setFormData({townId:townId});

        //const apiTown = await (new TownsClient()).getTown(townId, true); // true means get memebers for town
        //setTown(apiTown);
        //const apiMembers = await (new PeopleClient()).getAllPeople(townId);
        //setMembers(apiMembers);

        //setIsChair(user.personId === apiTown.chairPersonFk);

        setViewReady(true);

      }
      else
      {
        navigate("/Home"); //todo: not supposed to be here. must have typed url
      }

    })();

  }, []); // just once

  //#region event handlers
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  //#endregion

  //#region table, constants
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const navigate = useNavigate();

  const columns = [
    { field: 'namePersonLast', headerName: 'First name', width: 130, headerClassName: 'super-app-theme--header' },
    { field: 'namePersonFirst', headerName: 'Last name', width: 130, headerClassName: 'super-app-theme--header' },
    { field: 'emailPerson', headerName: 'Email', width: 250, headerClassName: 'super-app-theme--header' },
    { field: 'nameIdentity', headerName: 'LoginName', width: 250, headerClassName: 'super-app-theme--header' },
    {
      field: 'isChair', headerName: 'IsChair', width: 75, headerClassName: 'super-app-theme--header', valueGetter: (value) => {
        if (!value) {
          return "---";  //todo: why null?
        }
        return value === true ? "YES" : "NO"; // Convert the boolean value to a YES or NO
      },
    },
    { field: 'statPerson', headerName: 'Status', width: 100, headerClassName: 'super-app-theme--header' },
    {
      field: 'actions', type: 'actions', headerClassName: 'super-app-theme--header', getActions: (params) => [
        <DeleteUserActionItem
          label="Delete"
          showInMenu
          icon={<DeleteIcon />}
          //deleteUser={deleteUser(params.id)}
          closeMenuOnClick={false}
        />
      ]
    },
  ];
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 0 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  function a11yProps(index) {
    return { id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}`, };
  }

  function DeleteUserActionItem({ deleteUser, ...props }) {

    const [open, setOpen] = useState(false);

    return (
      <>
        <GridActionsCellItem {...props} onClick={() => setOpen(true)} />
        <Dialog fullScreen={"md"}
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete this user?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button
              onClick={() => {
                setOpen(false);
                deleteUser();
              }}
              color="warning"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
  //#endregion

  // content
  //-------------------------------------------------------------------------------------------------
  return <Paper component="form" noValidate autoComplete="off" sx={{ mt: 2, p: 2 }} onSubmit={handleSubmit(handleRegistration, handleError)}>

    <Alert sx={{ mb: 0 }} >{alertMessage}</Alert>

    <Box fullWidth>
      <TextField sx={{ mt: 1 }} name="nameFirst" label="Last First" fullWidth variant="outlined" type="text"
        value={formData.nameFirst} inputProps={{ readOnly: false }} {...register('nameFirst', registerOptions.nameFirst)}
        helperText={errors?.nameFirst && errors.nameFirst.message}
      />
      <TextField sx={{ mt: 1 }} name="nameLast" label="Last Last" fullWidth variant="outlined" type="text"
        value={formData.nameLast} inputProps={{ readOnly: false }} {...register('nameLast', registerOptions.nameLast)}
        helperText={errors?.nameLast && errors.nameLast.message}
      />
    </Box>

    <TextField sx={{ mt: 1 }} name="email" label="Email Address" fullWidth variant="outlined" type="text"
      value={formData.email} inputProps={{ readOnly: false }} {...register('email', registerOptions.email)}
      helperText={errors?.email && errors.email.message}
    />

    <Box>
      <TextField sx={{ mt: 1 }} name="addrRegistered" label="Street Address (where registered to vote)" fullWidth variant="outlined"
        value={formData.addrRegistered} type="text"
        inputProps={{ readOnly: false }} {...register('addrRegistered', registerOptions.addrRegistered)}
        helperText={errors?.addrRegistered && errors.addrRegistered.message}
      />
      <TextField sx={{ mt: 1 }} name="numZipCode" label="ZipCode" fullWidth variant="outlined"
        value={formData.numZipCode} type="text"
        inputProps={{ readOnly: false }} {...register('numZipCode', registerOptions.numZipCode)}
        helperText={errors?.numZipCode && errors.numZipCode.message}
      />
    </Box>

    <Box sx={{ mt: 1 }}><p></p></Box>

    <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
      CREATE NEW COMMITTEE-MEMBER
    </Button>

  </Paper>
}